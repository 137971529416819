const deepCopy = (obj) => {
    if (!obj) return obj; // Prevent undefined objects
    if (Array.isArray(obj)) {
      return obj.map(deepCopy);
    } else if (typeof obj === 'object') {
      const newObj = {};
      for (const key in obj) {
        newObj[key] = deepCopy(obj[key]);
      }
      return newObj;
    } else {
      return obj;
    }
  }

export default deepCopy