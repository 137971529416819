import React, { useContext, useReducer } from 'react'
import { lockedReducer } from '../Utils/reducers'
import { Box } from '@mui/material'
import ColorButton from '../Buttons/ColorButton'
import Schedule from './Schedule'
import Proposal from './Proposal'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import WorkIcon from '@mui/icons-material/Work';
import EngineeringIcon from '@mui/icons-material/Engineering';
import TourIcon from '@mui/icons-material/Tour';
import './styles.css'
import ServiceOrder from './ServiceOrder'
import Calendar from '../Calendar'
import { getSession } from '../../contexts/auth'
import { externalComponent } from '../AppRoutes'

const positions = (state, setState) => {
    const user = getSession()
    return ({
        'calendar': {name: 'Calendário', component: (<Calendar parent={state} setParent={setState}/>) , icon: (<CalendarMonthIcon />), permission: user.permissions.view_schedule | user.permissions.view_proposal | user.permissions.view_serviceorder},
        'schedule': { name: 'Agendamento de visitas',  component: (<Schedule setParent={setState} />), icon: (<TourIcon />), permission: user.permissions.view_schedule },
        'proposal': { name: 'Propostas',  component: (<Proposal parent={state} setParent={setState} />), icon: (<WorkIcon />), permission: user.permissions.view_proposal },
        'order': { name: 'Ordens de serviço', component: (<ServiceOrder parent={state} setParent={setState} />), icon: (<EngineeringIcon />), permissions: user.permissions.view_serviceorder }
    })
}

const Cockpit = () => {

    const [state, setState] = useReducer(lockedReducer, {
        position: 'calendar',
        selectedForward: null,
    })
    const positions_object = positions(state, setState)
    const position_list = Object.entries(positions_object)
    const {isMobile} = useContext(externalComponent)
    return (
        <>

            <Box className='cockpitmenu'>
                <Box className={isMobile ? 'cockpitbuttonMobile': 'cockpitbutton' }>
                    {position_list.map(([key, value]) => (
                        <ColorButton
                            startIcon={value.icon}
                            reverse={key === state.position ? false : true}
                            onClick={() => setState({ position: key })}>{value.name}
                        </ColorButton>
                    ))}
                </Box>
                    <Box sx={{ height: '100%', overflow: 'hidden' }}>
                        {positions_object[state.position]?.component}
                    </Box>
            </Box>

        </>
    )

}
export default Cockpit