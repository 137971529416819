import { Box } from "@mui/material"

const validateData = (data) => {

    const response = []

    switch (data?.step){
        case 0:
            if (!data?.client)
                response.push(<Box>Selecione um cliente.</Box>)
        break
        case 1:
            if (!data?.datetime)
                response.push(<Box>Selecione uma data.</Box>)
            if (!data?.responsable)
                response.push(<Box>Selecione um vendedor.</Box>)
            if (!data?.team)
                response.push(<Box>Selecione uma equipe.</Box>)
        break
        default:
            break
    }


    if (response.length === 0)
        return ''

    return (response)
}

export default validateData