import { Box, Tooltip } from "@mui/material";
import React from "react";

import './styles.css'

const renderValue = (row, column) => {
    const original_value = row[column.field]
    if (!original_value) return ''
    const value = column.valueGetter ? column.valueGetter({ row: row, value: original_value }) : original_value
    const final_value = column.renderCell ? column.renderCell({ row: row, value: value }) : value
    return (<Tooltip title={final_value}>{final_value}</Tooltip>)
}

const resumeColumns = (rows, column) => {
    if (column.count)
        return `${rows.length} Registro(s)`
    else if (column.sum) {
        const sum = rows.map(row => Number(row[column.field] || 0)).reduce((a, b) => a + b, 0)
        return column.valueGetter ? column.valueGetter({ value: sum }) : sum
    }

}

const ReportTable = ({ title, rows, columns, extrainfo }) => {

    return (
        <>
            {title && <Box className='report-main-title'>
                {title}
            </Box>}
            <Box className='report-main-table'>
                <Box className='report-main-headers' >
                    {columns.map(column => (
                        <Box className='report-row' sx={{ flex: column.flex, width: column.width }}>
                            {column.headerName}
                        </Box>
                    ))}
                </Box>
                <Box className='report-main-body'>
                    {rows.map(row => (
                        <Box className='report-main-row' >
                            {columns.map(column =>
                                <Box className='report-main-cell' sx={{ flex: column.flex, width: column.width }}>
                                    {renderValue(row, column)}
                                </Box>

                            )}
                        </Box>
                    ))}
                    <Box className='report-resume-row'>
                        {columns.map(column =>
                            <Box className='report-main-cell' sx={{ flex: column.flex, width: column.width }}>
                                {resumeColumns(rows, column)}
                            </Box>

                        )}
                    </Box>
                    {extrainfo && <Box className='report-extra-info'>
                        {extrainfo}
                    </Box>}
                </Box>

            </Box>
        </>
    )
}

export default ReportTable