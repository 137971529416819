import React, { useEffect, useReducer } from 'react'
import { lockedReducer } from '../../Utils/reducers'
import DefaultModal from '../../Utils/DefaultModal'
import Loader from '../../Loader/loader'
import { api } from '../../../services/api'
import toast from 'react-hot-toast'
import ColorButton from '../../Buttons/ColorButton'
import { Box, TextField } from '@mui/material'
import IntegerOnly from '../../Utils/IntegerOnly'

const url = 'sequences/'

const loadSequences = async (setState) => {
    setState({ loader: true })
    const res = await api.get(url + 'autoget/')
    if (res.status === 200)
        setState(res.data)

    setState({ loader: false })
}

const submit = async (state, setState, setParent) => {
    setState({ loader: true })
    const res = await api.patch(url + 'autoupdate/', state)
    if (res.status === 200) {
        toast.success('Sequências atualizadas com sucesso.')
        setParent({ editSequence: false })
    } else {
        toast.error(`Falha ao salvar as sequências: ${res.error}`)
        setParent({ editSequence: false })
    }
}

const ChangeSequence = ({ setParent }) => {

    const [state, setState] = useReducer(lockedReducer, {
        faes: 0,
        service_order: 0,
        loader: true
    })

    useEffect(() => {
        loadSequences(setState)
    }, [])

    return (
        <>
            <Loader external show={state.loader} />
            {!state.loader && <DefaultModal
                handleClose={() => setParent({ editSequence: false })}
                title='Alterar sequências'
                content={
                    <Box className='item-content-modal noborder' >
                        Selecione o valor da última sequencia
                        <Box className='item-profile-row'>
                            <TextField
                                  fullWidth
                                  size='small'
                                  value={state.service_order}
                                  onChange={(e) => setState({service_order: IntegerOnly(e.target.value, 0)})}
                                  inputProps={{ maxLength: 100 }}
                                  label='Ordem de serviço'
                            />
                        </Box>
                        <Box className='item-profile-row'>
                            <TextField
                                  fullWidth
                                  size='small'
                                  value={state.faes}
                                  onChange={(e) => setState({faes: IntegerOnly(e.target.value, 0)})}
                                  inputProps={{ maxLength: 100 }}
                                  label='FAES'
                            />
                        </Box>
                    </Box>
                }
                action={
                    <ColorButton
                        onClick={() => submit(state, setState, setParent)}
                    >
                        Salvar
                    </ColorButton>
                }
            />}
        </>
    )
}

export default ChangeSequence