import React, { useReducer, useContext} from 'react'
import { lockedReducer } from '../Utils/reducers'
import { api } from '../../services/api'
import ColorButton from '../Buttons/ColorButton';
import DefaultModal from '../Utils/DefaultModal';
import toast from 'react-hot-toast'
import { Box, Tooltip, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import validateData from './validateData';
import { externalComponent } from '../AppRoutes';
import { CepMask, CnpfMask, PhoneMask } from '../Utils/MaskedInput';
import { NumbersOnly } from '../Utils/IntegerOnly';
import cepLoad from '../Utils/Cepload';

const url = '/client/'

const getTitle = (data) => {
    if (data?.id)
        return 'Editar'
    return 'Adicionar'
}

const submit = async (data, getData, setLoader, handleClose, mode) => {

    setLoader(true)
    let res = null

    if (data.id)
        res = await api.patch(`${url}${data.id}/`, data)
    else
        res = await api.post(url, data)
    if (res.status === 201 || res.status === 200) {
        toast.success(`Pessoa ${res.status === 201 ? 'adicionada' : 'editada'} com sucesso`)
        if (mode === 'table')
            getData()
        else
            getData(res.data)
        handleClose()
        setLoader(false)
        return
    }
    toast.error(`Pessoa não pôde ser ${!data.id ? 'adicionada' : 'editada.'} Motivo: ${res.data}`)
    setLoader(false)
}

const handleCepComplete = async (e, setState, state) => {
    const cep = NumbersOnly(e.target.value);
    setState({ ...state, cep: cep });
    if (e.type === 'change' && cep.length === 8) {
        const addressData = await cepLoad(cep);
        if (!addressData.data.erro === true) {
            setState({
                street: addressData.data.logradouro,
                neighborhood: addressData.data.bairro,
                city: addressData.data.localidade,
                uf: addressData.data.uf,
            });
        }
    }
}



const EditClientModal = ({ data, handleClose, reloadData, readOnly, mode = 'table', types=[] }) => {

    const { setLoader } = useContext(externalComponent)

    const [state, setState] = useReducer(lockedReducer, {
        email: '',
        name: '',
        corporate_name: '',
        IE: '',
        contact: '',
        phone_number: '',
        complement: '',
        reference: '',
        street_number: '',
        cep: '',
        city: '',
        uf: '',
        street: '',
        neighborhood: '',
        cpf_cnpj: '',
        activity: '',
        type: 'CLIENT',
        ...data,
        id: data?.id || 0,

    })

    const validData = validateData(state)

    const content = (<>
        <Box className="item-profile-row">
            <TextField
                id="name"
                type="text"
                label="Nome"
                disabled={readOnly}
                size='small'
                value={state.name}
                onChange={(e) => { setState({ 'name': e.target.value }) }}
                fullWidth={false}
                inputProps={{
                    maxLength: 50
                }}
            />
        </Box>

        <Box className="item-profile-row">
            <TextField
                sx={{ flex: .8 }}
                id="corporate_name"
                type="text"
                label="Razão Social"
                disabled={readOnly}
                size='small'
                value={state.corporate_name}
                onChange={(e) => { setState({ 'corporate_name': e.target.value }) }}
                fullWidth={false}
                inputProps={{
                    maxLength: 50
                }}
            />

        </Box>

        <Box sx={{ display: 'flex', gap: 1 }}>
           {!readOnly &&  <FormControl sx={{ minWidth: 120 }} size='small' >
                <InputLabel id="select-type-label">Tipo</InputLabel>
                <Select
                    labelId="select-type-label"
                    id="select-type"
                    label="Tipo"
                    value={state.type}
                    onChange={(e) => { setState({ 'type': e.target.value }) }}
                >
                    {types.map(([key, value]) =>
                        <MenuItem value={key}>{value}</MenuItem>
                    )}

                </Select>
            </FormControl>}
            <TextField
                sx={{ flex: 1 }}
                id="activity"
                type="text"
                label="Atividade"
                disabled={readOnly}
                size='small'
                value={state.activity}
                onChange={(e) => { setState({ 'activity': e.target.value }) }}
                fullWidth={false}
                inputProps={{
                    maxLength: 50
                }}
            />
        </Box>


        <Box className='item-profile-row'>
            <TextField
                id="cpf_cnpj"
                type="text"
                label="CPF/CNPJ"
                disabled={readOnly}
                size='small'
                value={state.cpf_cnpj}
                onChange={(e) => { setState({ 'cpf_cnpj': NumbersOnly(e.target.value) }) }}
                fullWidth={false}
                InputProps={{
                    inputComponent: CnpfMask
                }}
            />

            <TextField
                id="IE"
                type="text"
                label="Insc. Estadual/RG"
                disabled={readOnly}
                value={state.IE}
                onChange={(e) => { setState({ 'IE': e.target.value }) }}
                fullWidth={false}
                size='small'
                inputProps={{
                    maxLength: 50
                }}
            />
        </Box>

        <Box sx={{ display: 'flex', gap: 1 }}>
            <TextField
                sx={{ flex: .5 }}
                id="phone_number"
                type="text"
                label="Telefone"
                disabled={readOnly}
                value={state.phone_number}
                onChange={(e) => { setState({ 'phone_number': NumbersOnly(e.target.value) }) }}
                fullWidth={false}
                size='small'
                InputProps={{
                    inputComponent: PhoneMask
                }}
            />

            <TextField
                sx={{ flex: 1 }}
                id="email"
                type="text"
                label="E-mail"
                size='small'
                disabled={readOnly}
                value={state.email}
                onChange={(e) => { setState({ 'email': e.target.value }) }}
                fullWidth={false}
                inputProps={{
                    maxLength: 100
                }}
            />
        </Box>

        <Box sx={{ display: 'flex', gap: 1 }}>
            <TextField
                sx={{ flex: 1 }}
                id="contact"
                type="text"
                label="Contato"
                disabled={readOnly}
                value={state.contact}
                onChange={(e) => { setState({ 'contact': e.target.value }) }}
                fullWidth={false}
                size='small'
                inputProps={{
                    maxLength: 100
                }}
            />

            <TextField
                sx={{ flex: .5 }}
                id="cep"
                type="text"
                label="CEP"
                disabled={readOnly}
                value={state.cep}
                onChange={(e) => handleCepComplete(e, setState, state)}
                fullWidth={false}
                size='small'
                InputProps={{
                    inputComponent: CepMask
                }}
            />
        </Box>

        <Box sx={{ display: 'flex', gap: 1 }}>

            <TextField
                sx={{ flex: 1 }}
                id="street"
                type="text"
                label="Rua"
                size='small'
                disabled={readOnly}
                value={state.street}
                onChange={(e) => { setState({ 'street': e.target.value }) }}
                fullWidth={false}
                inputProps={{
                    maxLength: 100
                }}
            />

            <TextField
                sx={{ flex: .2 }}
                id="street_number"
                type="text"
                label="Número"
                disabled={readOnly}
                size='small'
                value={state.street_number}
                onChange={(e) => { setState({ 'street_number': e.target.value }) }}
                fullWidth={false}
                inputProps={{
                    maxLength: 50
                }}
            />
        </Box>

        <Box sx={{ display: "flex", gap: 1 }}>
            <TextField
                sx={{ flex: 0.8 }}
                type="text"
                id="complement"
                disabled={readOnly}
                label="Complemento"
                value={state.complement}
                size='small'
                fullWidth={false}
                onChange={(e) => { setState({ 'complement': e.target.value }) }}
                inputProps={{
                    maxLength: 50
                }}
            />

            <TextField
                sx={{ flex: 1 }}
                type="text"
                id="reference"
                disabled={readOnly}
                label="Referência"
                value={state.reference}
                size='small'
                fullWidth={false}
                onChange={(e) => { setState({ 'reference': e.target.value }) }}
                inputProps={{
                    maxLength: 50
                }}
            />

        </Box>

        <Box sx={{ display: 'flex', gap: 1 }} >
            <TextField
                sx={{ flex: 1 }}
                id="neighborhood"
                type="text"
                label="Bairro"
                disabled={readOnly}
                value={state.neighborhood}
                onChange={(e) => { setState({ 'neighborhood': e.target.value }) }}
                fullWidth={false}
                size='small'
                inputProps={{
                    maxLength: 100
                }}
            />

            <TextField
                sx={{ flex: 1 }}
                id="city"
                type="text"
                label="Cidade"
                disabled={readOnly}
                value={state.city}
                onChange={(e) => { setState({ 'city': e.target.value }) }}
                fullWidth={false}
                size='small'
                inputProps={{
                    maxLength: 100
                }}
            />

            <TextField
                sx={{ flex: .2 }}
                id="uf"
                type="text"
                label="UF"
                disabled={readOnly}
                value={state.uf}
                onChange={(e) => { setState({ 'uf': e.target.value }) }}
                fullWidth={false}
                size='small'
                inputProps={{
                    maxLength: 100
                }}
            />
        </Box>


    </>)

    if (readOnly) return content

    return (
        <>
            <DefaultModal
                title={
                    `${getTitle(state)} Pessoa`
                }
                handleClose={handleClose}
                content={<Box className='item-content-modal noborder' >{content}</Box>}
                action={
                    <>
                        <Tooltip placement="left" title={validData}>
                            <Box>
                                <ColorButton
                                    onClick={() => submit(state, reloadData, setLoader, handleClose, mode)}
                                    disabled={validData}
                                >Salvar
                                </ColorButton>
                            </Box>
                        </Tooltip>
                    </>
                }
            />
        </>
    )
}

export default EditClientModal