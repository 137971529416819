import React, { useContext, useReducer, useEffect, useMemo } from 'react'
import { lockedReducer } from '../../Utils/reducers'
import { Box, FormControl, InputLabel, Select, MenuItem, Tooltip, IconButton, ListItemButton, ListItemText, List } from '@mui/material'
import { externalComponent } from '../../AppRoutes'
import { api } from '../../../services/api';
import { capitalize } from '../../Utils/functions'
import { MobileDateTimePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import ColorButton from '../../Buttons/ColorButton'
import ListIcon from '@mui/icons-material/List';
import DefaultModal from '../../Utils/DefaultModal'
import Table, { TableHook } from '../../Table'
import { number2dayweek, actionBar } from '../../Utils/dayjsptbrhelper';
import ReplyIcon from '@mui/icons-material/Reply'

const translate = (string) => {
    return string.replace('Can view historical', '')
}

const loadData = async (setState, setLoader) => {
    setLoader(true)
    const res = await api.get('/audit/permissions/')
    if (res.status === 200)
        setState({ permissions: res.data })
    setLoader(false)
}

const actionTranslate = {
    '': 'Nenhuma',
    '+': 'Criou',
    '~': 'Alterou',
    '-': 'Removeu'
}

const columns = (setState) => {
    return [
        {
            field: 'history_type', headerName: 'Ação', flex: 1, filterType: 'choices', choices: actionTranslate,
            valueGetter: ({ value }) => actionTranslate[value]
        },
        {
            field: 'history_date', headerName: 'Data de ação', flex: 1, filterable: false,
            valueGetter: ({ value }) => dayjs(value).toDate().toLocaleString()
        },
        { field: 'name_of_user', headerName: 'Ator', flex: 1, filterType: 'string' },
        {
            field: 'details', headerName: 'Detalhes', filterable: false,
            renderCell: ({ row }) => {
                return (
                    <Box >
                        <Tooltip title='Ver detalhes'><IconButton onClick={() => { setState({ editRow: row.details }) }}><ListIcon /></IconButton></Tooltip>
                    </Box>
                )
            },
            valueGetter: ({ value }) => JSON.stringify(value),
        }
    ]
}

const search = async (state, loadData) => {
    loadData('/audit/', {
        filterModel: {
            items: [], customItems: {
                field: state.field,
                history_date__gte: state.history_date__gte !== '' ? state.history_date__gte : '',
                history_date__lte: state.history_date__lte !== '' ? state.history_date__lte : ''
            }
        }
    })
}

const Audit = () => {
    const { setLoader, isMobile } = useContext(externalComponent)

    const [state, setState] = useReducer(lockedReducer, {
        permissions: [],
        rows: [],
        field: '',
        history_date__gte: '',
        history_date__lte: '',
        editRow: null
    })

    useEffect(() => {
        loadData(setState, setLoader)
    }, [setLoader])

    const [table,] = TableHook()

    return (
        <>
            {state.editRow &&
                <DefaultModal
                    title={`Ver detalhes`}
                    handleClose={() => setState({ editRow: null })}
                    content={
                        <List>
                            {Object.entries(state.editRow).map(([key, value]) =>
                                <ListItemButton>
                                    <ListItemText primary={key} secondary={JSON.stringify(value)} />
                                </ListItemButton>
                            )}
                        </List>
                    }
                />}
            <Box className='information-table'>
                <Box className='header'>
                    <Box className='left'>
                        <Box className='item-profile-row' sx={{ gap: '1rem', display: 'flex' }}>
                            {!isMobile && <Tooltip title='Voltar'>
                                <ColorButton
                                    className='profile-save-button minimized'
                                    onClick={() => window.backtobase()}
                                >
                                    <ReplyIcon />
                                </ColorButton>
                            </Tooltip>}
                            <FormControl sx={{ minWidth: 200, background: 'white' }} size='small' >
                                <InputLabel id="select-garantee-label">Tabela</InputLabel>
                                <Select
                                    labelId="select-garantee-label"
                                    id="select-garantee"
                                    label="Tabela"
                                    value={state.field}
                                    onChange={(e) => { setState({ 'field': e.target.value }) }}
                                >
                                    <MenuItem value={''}>Selecione *</MenuItem>
                                    {state.permissions.map((each) =>
                                        <MenuItem value={each.id}>{capitalize(translate(each.name))}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                            <MobileDateTimePicker
                                size='small'
                                label='Data de inicio'
                                value={dayjs(state.history_date__gte)}
                                format='DD/MM/YYYY HH:mm'
                                sx={{ background: 'white' }}
                                onChange={(e) => { setState({ 'history_date__gte': e.format('YYYY-MM-DD HH:mm') }) }}
                                slotProps={{ textField: { size: 'small' } }}
                                slots={{ toolbar: actionBar }}
                                dayOfWeekFormatter={(n, date) => number2dayweek[date.$W]}
                                ampm={false}
                            />

                            <MobileDateTimePicker
                                size='small'
                                label='Data de fim'
                                value={dayjs(state.history_date__lte)}
                                format='DD/MM/YYYY HH:mm'
                                sx={{ background: 'white' }}
                                onChange={(e) => { setState({ 'history_date__lte': e.format('YYYY-MM-DD HH:mm') }) }}
                                slotProps={{ textField: { size: 'small' } }}
                                slots={{ toolbar: actionBar }}
                                dayOfWeekFormatter={(n, date) => number2dayweek[date.$W]}
                                ampm={false}
                            />
                            <ColorButton
                                sx={{ height: '2.5rem' }}
                                disabled={state.field.length === 0}
                                onClick={() => search(state, (url, data) => table.reloadData(url, data))}
                            >Pesquisar</ColorButton>
                        </Box>

                    </Box>
                </Box>
                <Box className='user-table'>
                    <Table
                        rows={state.rows}
                        columns={useMemo(() => columns(setState), [])}
                        pagination
                        density='compact'
                        slots={{
                            filterComponent: true,
                            columnComponent: true,
                            filterState: { field: state.field, history_date__gte: state.history_date__gte, history_date__lte: state.history_date__lte }
                        }}
                        toolbar
                        getRowId={(row) => row.history_id}
                        updateRows={(rows) => setState({ 'rows': rows })}
                        innerRef={table}
                    />
                </Box>
            </Box>
        </>
    )
}

export default Audit