import React from 'react'
import { List, ListItem, ListItemButton, ListItemText, ListItemAvatar, Avatar, Tooltip, Box } from '@mui/material'
import dayjs from 'dayjs'
import { avatars, colors } from '.';
import { translations } from '.';

const forwardType = {
    'schedule': 'editSchedule',
    'proposal': 'editProposal',
    'serviceorder': 'editServiceOrder'
}

const Describe = ({data}) => {
    return (
        <Box sx={{display:'flex', flexDirection: 'column'}}>
            <Box>Cliente: {data.client_name}</Box>
            <Box>Data: {dayjs(data.datetime).format('DD/MM/YYYY HH:mm')}</Box>
            <Box>Tipo: {translations[data.type]}</Box>
            <Box>Status: {translations[data.state]}</Box>
            {data.team_name && <Box>Equipe: {data.team_name}</Box>}
            {data.service_names && <Box>Serviços: {data.service_names}</Box>}
        </Box>
    )
}

const MonthCell = ({ children, openItem }) => {

    return (
        <List dense={true}>
            {children.map(each => (
                <Tooltip title={<Describe data={each}/>} >
                    <ListItem disableGutters disablePadding key={`${each.type}-${each.id}`}>
                        <ListItemButton
                            onClick={() => openItem({ [forwardType[each.type]]: each })}
                            sx={{ gap: '1rem', padding: 0, paddingLeft: '0.5rem' }}
                            dense={true}>
                            <ListItemAvatar sx={{
                                minWidth: '20px'
                            }}>
                                <Avatar sx={{
                                    bgcolor: 'transparent', color: colors[each.state],
                                    height: '25px',
                                    width: '25px'
                                }}>
                                    {avatars[each.type]}
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                className='calendar-item-text'
                                primary={each.client_name}
                                secondary={dayjs(each.datetime).format('HH:mm')}
                            >
                            </ListItemText>
                        </ListItemButton>
                    </ListItem>
                </Tooltip>
            ))}
        </List>
    )
}

export default MonthCell