import React, { useState } from 'react'
import DefaultModal from "./DefaultModal";
import ColorButton from '../Buttons/ColorButton';
import { TextField } from '@mui/material';

const ConfirmationDialog = ({ content, handleClose, onConfirm, okButton, message }) => {

    const [text, setText] = useState('')

    return (
        <DefaultModal
            title='Confirmação'
            content={
                <>
                    {content}
                    {message && <TextField
                        multiline
                        fullWidth
                        sx={{marginTop: '1rem'}}
                        maxRows={4}
                        size='small'
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        inputProps={{ maxLength: 5000 }}
                        label='Motivo'
                    />}
                </>
            }
            handleClose={handleClose}
            action={<ColorButton disabled={message && text.length === 0} onClick={()=> onConfirm(text)} >{okButton ? okButton : 'Sim'}</ColorButton>}
        />
    )
}

export default ConfirmationDialog