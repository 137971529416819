import React, { useContext, useMemo, useReducer } from 'react'

import { Box, IconButton, Tooltip } from '@mui/material'
import { api } from '../../../services/api';
import toast from 'react-hot-toast'
import { getSession } from '../../../contexts/auth';
import { lockedReducer } from '../../Utils/reducers';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ConfirmationDialog from '../../Utils/ConfirmationDialog';
import { externalComponent } from '../../AppRoutes';
import EditScheduleModal from './Edit-schedule-modal';
import dayjs from 'dayjs';
import Table, { TableHook } from '../../Table';
import { ResponsiveActionBox } from '../../Table/mobileTable';


export const defaultChoices = {
    '': 'Nenhum',
    'OPEN': 'Aberto',
    'SCHEDLED': 'Agendado',
    'DONE': 'Realizado',
    'CANCELLED': 'Cancelado'
}

const url = '/order/schedule/'

const submitDelete = async (data, setState, getData, setLoader) => {
    setLoader(true)
    const res = await api.delete(`${url}${data.id}/`)
    setLoader(false)
    if (res.status === 204) {
        toast.success('Deletado com sucesso')
        setState({ deleteRow: false })
        getData()
        return
    }
    console.error('Falha ao deletar')

}

const columns = (setState, permissions) => {
    return [
        {
            field: 'identifier', headerName: 'Identificador', flex: 1, filterType: 'number'
        },
        {
            field: 'client_name', headerName: 'Cliente', flex: 1, filterType: 'string'
        },
        {
            field: 'created_at', headerName: 'Data de criação', flex: 1, filterType: 'daterange',
            valueGetter: ({ value }) => dayjs(value).toDate().toLocaleString()
        },
        {
            field: 'datetime', headerName: 'Data da visita', flex: 1, filterType: 'daterange',
            valueGetter: ({ value }) => value ? dayjs(value).toDate().toLocaleString() : ''
        },
        { field: 'state', headerName: 'Status', flex: 1, filterType: 'choices', choices:defaultChoices,
            valueGetter: ({row}) => row.status
        },
        {
            field: 'actions', headerName: 'Ações', filterable: false,
            renderCell: (props) => {
                return (
                    <ResponsiveActionBox >
                        {permissions.change_schedule && <Tooltip title='Editar visita'>
                            <IconButton onClick={() => { setState({ editRow: props.row }) }}><EditIcon /></IconButton>
                        </Tooltip>}
                        {permissions.delete_schedule && <Tooltip title='Excluir visita'>
                            <IconButton onClick={() => { setState({ deleteRow: props.row }) }}><DeleteIcon /></IconButton>
                        </Tooltip>}
                    </ResponsiveActionBox>
                )
            }
        }
    ]
}


const Schedule = ({ setParent }) => {

    const { setLoader } = useContext(externalComponent)

    const user = getSession()

    const [state, setState] = useReducer(lockedReducer, {
        rows: [],
        loading: false,
        editRow: null,
        deleteRow: null
    })

    const [table, reloadData] = TableHook()

    return (
        <>
            {state.deleteRow &&
                <ConfirmationDialog
                    content={`Deseja excluir o agendamento ${state.deleteRow.identifier}?`}
                    handleClose={() => setState({ deleteRow: null })}
                    onConfirm={() => submitDelete(state.deleteRow, setState, reloadData, setLoader)}
                />
            }
            {state.editRow &&
                <EditScheduleModal
                    data={state.editRow}
                    handleClose={() => setState({ editRow: null })}
                    reloadData={reloadData}
                    openProposal={forward => setParent({ position: 'proposal', selectedForward: forward })}
                />
            }
            <Box className='information-table'>
                <Box className='user-table'>
                    <Table
                        rows={state.rows}
                        columns={useMemo(()=> columns(setState, user.permissions), [user.permissions])}
                        pagination
                        density='compact'
                        slots={{
                            addComponent: user.permissions.add_schedule ? () => setState({ editRow: {} }) : null,
                            filterComponent: true,
                            columnComponent: true
                        }}
                        toolbar
                        getRowClassName={(params) => `dddrow--${params.row.state}`}
                        getURL={url}
                        updateRows={(rows) => setState({'rows': rows})}
                        innerRef={table}
                        mobile={true}
                        mobileProps= {
                            {
                                title: ['client_name'],
                                secondary: [
                                    ['created_at'], ['datetime'], 
                                ]
                            }
                        }
                    />
                </Box>
            </Box>
        </>
    )
}

export default Schedule