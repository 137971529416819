import React, { useContext, useMemo, useReducer } from "react";

import { Box, IconButton, Tooltip } from "@mui/material";
import { api } from "../../../services/api";
import toast from "react-hot-toast";
import { getSession } from "../../../contexts/auth";
import { lockedReducer } from "../../Utils/reducers";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ConfirmationDialog from "../../Utils/ConfirmationDialog";
import { externalComponent } from "../../AppRoutes";
import EditEquipmentModal from "./Edit-equipment-modal";
import Table, { TableHook } from "../../Table";
import { ResponsiveActionBox } from "../../Table/mobileTable";

const url = "/equipment/";

const submitDelete = async (data, setState, getData, setLoader) => {
  setLoader(true);
  const res = await api.delete(`${url}${data.id}/`);
  setLoader(false);
  if (res.status === 204) {
    toast.success("Deletado com sucesso");
    setState({ deleteRow: false });
    getData();
    return;
  }
  console.error("Falha ao deletar");
};

const columns = (setState, permissions) => {
  return [
    {
      field: "identifier",
      headerName: "Identificador",
      flex: 1,
      filterType: "number",
    },
    { field: "name", headerName: "Nome", flex: 1, filterType: "string" },
    {
      field: "actions",
      headerName: "Ações",
      filterable: false,
      renderCell: (props) => {
        return (
          <ResponsiveActionBox>
            {permissions.change_equipment && (
              <Tooltip title="Editar equipamento">
                <IconButton
                  onClick={() => {
                    setState({ editRow: props.row });
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}
            {permissions.delete_equipment && (
              <Tooltip title="Excluir equipamento">
                <IconButton
                  onClick={() => {
                    setState({ deleteRow: props.row });
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
          </ResponsiveActionBox>
        );
      },
    },
  ];
};

const Equipment = () => {
  const { setLoader } = useContext(externalComponent);

  const user = getSession();

  const [state, setState] = useReducer(lockedReducer, {
    rows: [],
    editRow: null,
    deleteRow: null,
  });

  const [table, reloadData] = TableHook();

  return (
    <>
      {state.deleteRow && (
        <ConfirmationDialog
          content={`Deseja excluir o equipamento ${state.deleteRow.name} - ${state.deleteRow.identifier}?`}
          handleClose={() => setState({ deleteRow: null })}
          onConfirm={() =>
            submitDelete(state.deleteRow, setState, reloadData, setLoader)
          }
        />
      )}
      {state.editRow && (
        <EditEquipmentModal
          data={state.editRow}
          handleClose={() => setState({ editRow: null })}
          reloadData={reloadData}
        />
      )}
      <Box className="information-table">
        <Box className="user-table">
          <Table
            rows={state.rows}
            columns={useMemo(
              () => columns(setState, user.permissions),
              [user.permissions]
            )}
            pagination
            density="compact"
            slots={{
              returnComponent: ()=> window.backtobase(),
              addComponent: () =>
                user.permissions.add_equipment
                  ? setState({ editRow: {} })
                  : null,
              filterComponent: true,
              columnComponent: true,
            }}
            toolbar
            getURL={url}
            updateRows={(rows) => setState({ rows: rows })}
            innerRef={table}
            mobile={true}
            mobileProps={{
              title: ["document"],
              secondary: [["name"]],
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default Equipment;
