import React, { useCallback, useContext, useEffect, useReducer } from 'react';
import { Box } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import './style.css';
import { api } from '../../../services/api';
import toast from 'react-hot-toast';
import { lockedReducer } from '../../Utils/reducers';
import { externalComponent } from '../../AppRoutes';
import MegaAutoComplete from '../../Utils/megaautocomplete';
import ColorButton from '../../Buttons/ColorButton';
import ReplyIcon from '@mui/icons-material/Reply'

const url = '/financesettings/';

const Finance = () => {
    const { setLoader, isMobile } = useContext(externalComponent)

    const [state, setState] = useReducer(lockedReducer, {
        costcenter: '',
        revenue: '',
        id: 0
    });


    const saveData = useCallback(async () => {
        setLoader(true)
        let res = null
        if (state.id) {
            res = await api.patch(`${url}${state.id}/`, state)
        }
        else {
            res = await api.post(`${url}`, state)
        }
        setLoader(false)
        if ([200, 201].includes(res.status)) {
            const data = res.data
            setState(data)
            toast.success('Dados salvos com sucesso')
            return
        }
        toast.error('Ocorreu um erro ao salvar os dados')
    }, [setLoader, state]);


    const loadData = useCallback(async () => {
        setLoader(true)
        const res = await api.get(`/financesettings/`)
        setLoader(false)
        if (res.status === 200) {
            if (res.data.results.length > 0) {
                const data = res.data.results[0]
                setState({
                    id: data.id,
                    costcenter: data.costcenter,
                    revenue: data.revenue,
                })
            }
            return
        }
        toast.error('Ocorreu um erro ao encontrar os dados de financeiro')

    }, [setLoader])

    useEffect(() => {
        loadData()
    }, [setLoader, loadData]);

    return (
        <>
            <Box sx={{ display: 'flex', gap: '1rem' }}>
                {!isMobile && <ColorButton
                    className='profile-save-button'
                    onClick={() => window.backtobase()}
                >
                    <Box className='flexbox'><ReplyIcon /> Voltar</Box>
                </ColorButton>}

                <ColorButton
                    className='profile-save-button'
                    onClick={() => saveData(state, setState)}
                >
                    <Box className='flexbox'><SaveIcon />Salvar</Box>
                </ColorButton>
            </Box>
            <Box className="item-content-modal finance item-padding">
                <h3>Configuração Financeiro</h3>
                <Box>
                    <MegaAutoComplete
                        sx={{}}
                        label={'Receita'}
                        noOptionsText={'Digite para procurar uma receita'}
                        value={state.revenue}
                        onChange={(data) => setState({ revenue: data })}
                        integrated
                        integrated_path='/finance/'
                        timeoutUrl='/api/revenue/'
                        timeoutParam='name'
                    />
                    <MegaAutoComplete
                        sx={{ paddingTop: "20px", paddingBottom: "20px" }}
                        label={'Centro de Custo'}
                        noOptionsText={'Digite para procurar um centro de custo'}
                        value={state.costcenter}
                        onChange={(data) => setState({ costcenter: data })}
                        integrated
                        integrated_path='/finance/'
                        timeoutUrl='/api/costcenter/'
                        timeoutParam='name'
                    />
                </Box>
            </Box>
        </>
    );
};

export default Finance;