import React, { useReducer, useContext, useState, useEffect } from 'react'
import { lockedReducer } from '../../Utils/reducers'
import { api } from '../../../services/api'
import ColorButton from '../../Buttons/ColorButton';
import DefaultModal from '../../Utils/DefaultModal';
import toast from 'react-hot-toast'
import { Box, Tooltip, TextField, Select, InputLabel, FormControl, MenuItem } from '@mui/material'
import validateData from './validateData';
import { externalComponent } from '../../AppRoutes';
import { capitalize } from '../../Utils/functions'
import IntegerOnly from '../../Utils/IntegerOnly';

const url = '/plague/'

const getTitle = (data) => {
    if (data?.id)
        return 'Editar'
    return 'Adicionar'
}

const submit = async (data, getData, setLoader, handleClose) => {

    setLoader(true)
    let res = null

    if (data.id)
        res = await api.patch(`${url}${data.id}/`, data)
    else
        res = await api.post(url, data)
    if (res.status === 201 || res.status === 200) {
        toast.success(`Item ${res.status === 201 ? 'adicionado' : 'editado'} com sucesso`)
        getData()
        handleClose()
        setLoader(false)
        return
    }
    toast.error(`Item não pôde ser ${!data.id ? 'adicionado' : 'editado.'} Motivo: ${res.data}`)
    setLoader(false)
}

const getGarantees = async (setGarantees) => {
    const res = await api.get(url + 'garantee/')
    if (res.status === 200)
        setGarantees(res.data)
}

const EditPlagueModal = ({ data, handleClose, reloadData, items = {} }) => {

    const { setLoader } = useContext(externalComponent)

    const [state, setState] = useReducer(lockedReducer, {
        name: '',
        cientific_name: '',
        garantee: '',
        code: 0,
        type: '',
        ...data,
        id: data.id ? data.id : 0
    })

    const [garantees, setGarantees] = useState([])

    useEffect(() => {
        getGarantees(setGarantees)
    }, [])

    const validData = validateData(state)

    return (
        <>
            <DefaultModal
                title={
                    `${getTitle(state)} Item`
                }
                handleClose={handleClose}
                content={
                    <>
                        <Box className='item-content-modal noborder' >
                            <Box className='item-profile-row'>
                                <TextField
                                    id="name"
                                    type="text"
                                    label="Nome"
                                    value={state.name}
                                    onChange={(e) => { setState({ 'name': e.target.value }) }}
                                    fullWidth={false}
                                    size='small'
                                    inputProps={{
                                        maxLength: 50
                                    }}
                                />

                            </Box>
                            <Box className='item-profile-row'>
                                <TextField
                                    id="cientific_name"
                                    type="text"
                                    label="Nome científico"
                                    size='small'
                                    value={state.cientific_name}
                                    onChange={(e) => { setState({ 'cientific_name': e.target.value }) }}
                                    fullWidth={false}
                                    inputProps={{
                                        maxLength: 100
                                    }}
                                />
                            </Box>
                            <Box className='item-profile-row'>
                                <FormControl sx={{ minWidth: 120 }} size='small' >
                                    <InputLabel id="select-garantee-label">Garantia</InputLabel>
                                    <Select
                                        labelId="select-garantee-label"
                                        id="select-garantee"
                                        label="Garantia"
                                        value={state.garantee}
                                        onChange={(e) => { setState({ 'garantee': e.target.value }) }}
                                    >
                                        {garantees.map(([key, value]) =>
                                            <MenuItem value={key}>{capitalize(value)}</MenuItem>
                                        )}

                                    </Select>
                                </FormControl>
                                <FormControl sx={{ minWidth: 120 }} size='small' >
                                    <InputLabel id="select-items-label">Tipo</InputLabel>
                                    <Select
                                        labelId="select-items-label"
                                        id="select-items"
                                        label="Tipo"
                                        value={state.type}
                                        onChange={(e) => { setState({ 'type': e.target.value }) }}
                                    >
                                        {Object.entries(items).map(([key, value]) =>
                                            <MenuItem value={key}>{value}</MenuItem>
                                        )}

                                    </Select>
                                </FormControl>
                                <TextField
                                    id="code"
                                    type="text"
                                    label="Código"
                                    size='small'
                                    value={state.code}
                                    onChange={(e) => { setState({ 'code': IntegerOnly(e.target.value) }) }}
                                    fullWidth={false}
                                    inputProps={{
                                        maxLength: 4
                                    }}
                                />
                            </Box>
                        </Box>
                    </>
                }
                action={
                    <>
                        <Tooltip placement="left" title={validData}>
                            <Box>
                                <ColorButton
                                    onClick={() => submit(state, reloadData, setLoader, handleClose)}
                                    disabled={validData}
                                >Salvar
                                </ColorButton>
                            </Box>
                        </Tooltip>
                    </>
                }
            />
        </>
    )
}

export default EditPlagueModal