import { Box } from "@mui/material"

const validateData = (data) => {

    const response = []

    switch (data?.step) {
        case 0:
            if (!data?.responsable)
                response.push(<Box>Selecione um responsável técnico.</Box>)
            if (!data.client_name && !data.client?.label && !data.new_client)
                response.push(<Box>Selecione um cliente.</Box>)
            if (!data.seller_name && !data.schedule_responsable?.label && !data.new_seller)
                response.push(<Box>Selecione um vendedor.</Box>)
            break
        case 1:
            if (data?.services.length === 0)
                response.push(<Box>Selecione um serviço.</Box>)
            if (data.services.length > 0 && data.services.find(each => !each.value))
                response.push(<Box>Preencha todos os valores dos serviços.</Box>)
            if (data.services.length > 0) {
                if (data.services.find(service => service.products?.find(product => !product.value)))
                    response.push(<Box>Preencha todos os valores dos produtos.</Box>)
            }
            break
        case 2:
            if (!data?.garantee)
                response.push(<Box>Selecione a validade da proposta.</Box>)
            if (!data?.payment)
                response.push(<Box>Escolhe uma forma de pagamento.</Box>)
            if (!data.payment?.data?.parcel_fix && !data.payment_data?.parcels)
                response.push(<Box>Escolhe a quantidade de parcelas.</Box>)
            break
        default:
            break
    }


    if (response.length === 0)
        return ''

    return (response)
}

export default validateData