import { Box } from "@mui/material"

const validateData = (data) => {

    const response = []

    if (!data?.name?.length > 0)
        response.push(<Box>Digite o nome do cliente.</Box>)

    // if (!data?.email?.includes('@'))
    //     response.push(<Box>Email não contém caracter @.</Box>)
    
    // if (!data?.cpf_cnpj?.length > 0)
    //     response.push(<Box>Digite o CPF/CNPJ do cliente</Box>)
    
    if (response.length === 0)
        return ''

    return (response)
}

export default validateData