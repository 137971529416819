
import dayjs from 'dayjs'

export const createMonthArr = (month, year) => {
    const firstday = dayjs().set('date', 1).set('month', month).set('year', year).set('hour', 12).set('minutes', 12)
    let cursor = firstday.subtract(firstday.$W, 'day')
    const nextmonth = firstday.add(1, 'month')
    const dayArr = []

    while (cursor <= nextmonth){
        dayArr.push(cursor)
        cursor = cursor.add(1, 'day')
    }
    while(cursor.$W < 6){
        dayArr.push(cursor)
        cursor = cursor.add(1, 'day')
    }
    if (cursor.$W === 6)
        dayArr.push(cursor)
    return dayArr
}

export const createWeekArr = (day, month, year) => {
    const firstday = dayjs().set('date', day).set('month', month).set('year', year)
    let cursor = firstday.subtract(4, 'day')
    const lastDay = firstday.add(4, 'day')
    const dayArr = []
    while (cursor <= lastDay){
        dayArr.push(cursor)
        cursor = cursor.add(1, 'day')
    }
    return dayArr
}

