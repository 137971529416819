import { useState, useContext, createContext, useLayoutEffect, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import "./styles.css";

import LoginComponent from "./Login/login";
import MenuComponent from "./Menu/menu";

import { Box } from "@mui/material";

import { AuthProvider, AuthContext, getSession } from "../contexts/auth";

import Home from "./Home/home";
import Loader from "./Loader/loader";

import Settings from "./Settings";
import { Toaster } from 'react-hot-toast'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ptBR as datagridptBR } from '@mui/x-data-grid';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ptBR from 'dayjs/locale/pt-br';
import { ptBR as localePTBR } from '@mui/x-date-pickers/locales';
import Clients from "./Clients";
import Cadastros from "./Cadastros";
import Cockpit from "./Cockpit";
import apptheme from "./theme/default";
import Finance from "./Finance";
import Relatorios from "./Relatorios";

export const mobileWidth = '768'

export const externalComponent = createContext()

const theme = createTheme(
  {
    palette: {
      primary: { main: apptheme.tertiaryColor },
    },
    components: {
      MuiStepIcon: {
        color: 'black'
      }
    }
  },
  datagridptBR,
);

export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return size;
}

const PrivateRoute = ({ botmenu, permission, component, frame }) => {
  const [width] = useWindowSize();
  const { authenticated, loading } = useContext(AuthContext);
  const [loader, setLoader] = useState(false)
  const [screenKey, setScreenKey] = useState(Math.random())
  const isMobile = width <= mobileWidth

  useEffect(()=>{
    window.setScreenKey = setScreenKey
  }, [])

  if (loading) {
    return <Loader external show={true} />;
  }

  if (!authenticated) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={ptBR}
          localeText={localePTBR.components.MuiLocalizationProvider.defaultProps.localeText}
        >
          <externalComponent.Provider value={{ loader, setLoader, isMobile }}>

            <Loader external show={loader} />
            {!frame ? <Box  className="main-screen">
              <Box sx={{ flex: 9, overflow: 'hidden' }}>{component &&
                <Box  className={'default_padding'}>
                  {isMobile && <Box sx={{ height: '3rem', padding: '1rem' }}></Box>}
                  {component}
                </Box>
              }
              </Box>

              < >{botmenu && <MenuComponent key={`screen-key-${screenKey}`}/>}</>
            </Box>
            : (component)}
          </externalComponent.Provider>
        </LocalizationProvider>
      </ThemeProvider>
    </>

  );
};



const AppRoutes = () => {

  const user = getSession()

  return (
    <Router>
      <Toaster position='bottom-center' />
      <AuthProvider>
        <Routes>
          <Route exact path="/login" element={<LoginComponent />} />
          <Route
            exact
            path="/"
            element={
              <PrivateRoute botmenu permission={true} component={<Home />} />
            }
          />
          <Route
            exact
            path="/settings"
            element={
              <PrivateRoute
                botmenu
                permission={true}
                component={<Settings />}
              />
            }
          />
          <Route
            exact
            path="/clients"
            element={
              <PrivateRoute
                botmenu
                permission={true}
                component={<Clients />}
              />

            }
          />
          <Route
            exact
            path="/cadastros"
            element={
              <PrivateRoute
                botmenu
                permission={
                  true
                }
                component={<Cadastros />}
              />

            }
          />
          <Route
            exact
            path="/cockpit"
            element={
              <PrivateRoute
                botmenu
                permission={true}
                component={<Cockpit />}
              />

            }
          />
          <Route
            exact
            path="/finance"
            element={
              <PrivateRoute
                botmenu
                permission={user?.finance}
                component={<Finance />}
                frame
              />

            }
          />
          <Route
            exact
            path="/reports"
            element={
              <PrivateRoute
                botmenu
                permission={true}
                component={<Relatorios />}
              />

            }
          />
        </Routes>

      </AuthProvider>
    </Router>
  );
};

export default AppRoutes;
