import React, { useContext, useEffect, useReducer, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { lockedReducer } from '../Utils/reducers'
import { api } from '../../services/api'
import { externalComponent } from '../AppRoutes'
import { Box } from '@mui/material'
import toast from 'react-hot-toast'

const pingFinance = (navigate, setLoader) => {
    toast.error('Financeiro está offline')
    navigate('/cockpit')
    setLoader(false)
}

const initialize = async (setState, setLoader, navigate) => {
    setLoader(true)
    const res = await api.get('finance_login/')
    if (res.status === 200) {
        setState({ url: res.data })
        return
    }
    pingFinance(navigate, setLoader)
}

const Finance = () => {
    const navigate = useNavigate()
    const frameRef = useRef()
    const { setLoader } = useContext(externalComponent)
    const [state, setState] = useReducer(lockedReducer, {
        url: null
    })

    useEffect(() => {
        window.addEventListener('message', (e) => {
            if (e.data === 'closeParent')
                navigate('/cockpit')
        })
        initialize(setState, setLoader, navigate)
    }, [navigate, setLoader])
    return (
        <Box sx={{display: 'flex', width: '100svw', height: '100svh'}}>
            {state.url &&
                <iframe
                    ref={frameRef}
                    width="100%"
                    height="100%"
                    onLoad={() => {setLoader(false)}}
                    onError={() => pingFinance(navigate, setLoader)}
                    title='financeFrame'
                    src={state.url}>
                </iframe>
            }
        </Box>
    )
}

export default Finance