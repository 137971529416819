import { Box } from "@mui/material"

const validateData = (data) => {

    const response = []

    if (!data?.name?.length > 0)
        response.push(<Box>Digite o nome do serviço.</Box>)
    if (!data?.equipment)
        response.push(<Box>Selecione um equipamento.</Box>)

    if (response.length === 0)
        return ''

    return (response)
}

export default validateData