import React, { useContext, useEffect, useReducer } from 'react'
import DefaultModal from '../../Utils/DefaultModal'
import { lockedReducer } from '../../Utils/reducers'
import { api } from '../../../services/api'
import dayjs from 'dayjs'
import { externalComponent } from '../../AppRoutes'
import { MobileDateTimePicker } from '@mui/x-date-pickers'
import { Box, ListItem, ListItemButton, ListItemIcon, ListItemText, TextField, List } from '@mui/material'
import IntegerOnly from '../../Utils/IntegerOnly'
import { number2dayweek } from '../../Utils/dayjsptbrhelper'
import { actionBar } from '../../Utils/dayjsptbrhelper'
import ColorButton from '../../Buttons/ColorButton'

import EngineeringIcon from '@mui/icons-material/Engineering';
import TourIcon from '@mui/icons-material/Tour';

import './styles.css'

const url = '/order/schedule/dayschedule/'

const typedict = {
    'Ordem de serviço': <EngineeringIcon />,
    'Rascunho': <TourIcon />,
    'Avaliação Prévia': <TourIcon />,
}

const getDayData = async (date, setLoader, setState) => {
    setLoader(true)
    const res = await api.post(url, { datetime__gte: date.format('YYYY-MM-DD 00:00Z'), datetime__lt: date.add(1, 'day').format('YYYY-MM-DD 00:00Z'), data_only: true })
    setLoader(false)
    if (res.status === 200) {
        setState({ rows: res.data.map(each => each.data).flat(1).map( e => (
            {...e, time: dayjs(e.time), endtime: dayjs(e.endtime)}
        )
    )})
    }
}

const prepareList = (rows, id, datetime, duration) => {

    const hasRow = rows.find(e=> e.id === id) || {}
    const newRow = {
        ...hasRow,
        special: true,
        type: 'Rascunho',
        time: datetime,
        endtime: datetime.add(duration, 'minutes'),
        id: 0,
    }

    return [...rows.filter(e=> e.id !== id), newRow].toSorted((a,b) => a.time - b.time)
}

const ShowDisponibility = ({ id, initialDay, duration, handleClose, submitDate }) => {
    const { setLoader } = useContext(externalComponent)
    const [state, setState] = useReducer(lockedReducer, {
        datetime: dayjs(initialDay) || dayjs(),
        duration: duration || 0,
        rows: [],
        valid: false,
    })

    useEffect(() => {
        getDayData(state.datetime, setLoader, setState)
    }, [state.datetime, setLoader])


    return (
        <DefaultModal
            title={`Disponibilidade do dia ${state.datetime.format('DD/MM/YYYY')}`}
            handleClose={handleClose}
            content={
                <>
                    <Box className='item-profile-row'>
                        <Box sx={{ display: 'flex', gap: '1rem' }}>
                            <MobileDateTimePicker
                                size='small'
                                label='Data e hora'
                                value={dayjs(state.datetime)}
                                format='DD/MM/YYYY HH:mm'
                                sx={{ flex: .7 }}
                                onChange={(e) => { setState({ 'datetime': e }) }}
                                slotProps={{ textField: { size: 'small' } }}
                                slots={{ toolbar: actionBar }}
                                dayOfWeekFormatter={(n, date) => number2dayweek[date.$W]}
                                ampm={false}
                            />
                            <TextField
                                size='small'
                                sx={{ flex: .3 }}
                                label='Duração (minutos)'
                                value={state.duration}
                                onChange={(e) => setState({ duration: IntegerOnly(e.target.value, 0, 9999) })}
                            />
                        </Box>
                    </Box>
                    <Box sx={{paddingTop:'1rem'}} className='disponibility-box'>
                        <List disablePadding>
                            {prepareList(state.rows, id, state.datetime, state.duration).map(row => (
                                <ListItem disablePadding>
                                    <ListItemButton disablePadding className={`disponibility-border ${row.special ? 'disponibility-special' : ''}`}>
                                       <ListItemIcon disablePadding disableGutters >{typedict[row.type]}</ListItemIcon>
                                        <ListItemText disablePadding disableGutters primary={
                                            <>
                                                {row.time.format('HH:mm')} - {row.endtime.format('HH:mm')}
                                            </>
                                        } secondary={
                                            <>
                                            {row.special ? 'Agendamento atual' :
                                            <>{row.team} - {row.client?.name} - {row.service}</>}
                                            
                                            </>
                                        }/>
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List> 
                    </Box>
                </>
            }
            action={
                <ColorButton
                    onClick={() => submitDate(state.datetime, state.duration)}
                    disabled={state.valid}>
                    Concluir
                </ColorButton>
            }
        />
    )
}

export default ShowDisponibility