import AppRoutes from "./components/AppRoutes";
console.disableYellowBox = true;

function App() {

    return(
      <div>
        <AppRoutes/>
      </div>
      );
  }

 


export default App;
