import React, { useEffect, useReducer, useContext } from 'react'

import { Box, Autocomplete, TextField, List, CircularProgress, InputLabel, MenuItem, ListItem, FormControl, Select, Tooltip } from '@mui/material'
import timeoutList from '../../Utils/TimeoutList'
import ColorButton from '../../Buttons/ColorButton'
import { NumericFormatInput } from '../../Utils/MaskedInput'
import deepCopy from '../../Utils/deepCopy'
import { FloatOnly } from '../../Utils/IntegerOnly'
import { capitalize } from '../../Utils/functions'
import { lockedReducer } from '../../Utils/reducers'
import DefaultModal from '../../Utils/DefaultModal'
import { api } from '../../../services/api'
import toast from 'react-hot-toast'
import { externalComponent } from '../../AppRoutes'
import validateData from './validateData'

const ServiceSelectionMain = ({ state, setState, item_types = {}, hideSelection }) => {
    return (
        <>
            {!hideSelection && <Box sx={{ display: 'flex', gap: '1rem' }}>
                <Box className='multi-button-selector'>
                    {Object.entries(item_types).map(([key, value]) => (
                        <ColorButton sx={{ flex: 1 }} reverse={state.item_type !== key} onClick={() => setState({ item_type: key, services: [], loaded_services: [] })}>{value}</ColorButton>
                    ))}
                </Box>
            </Box>}
            {(state.item_type) && <Box sx={{ display: 'flex', gap: '1rem' }}>
                <Autocomplete
                    multiple
                    sx={{ flex: 1 }}
                    id={'autocompletebox-service'}
                    value={state.services}
                    options={state.loaded_services.filter(each => !state.services.map(e => e.id).includes(each.id))}
                    onChange={(e, selectedServices) => { setState({ services: selectedServices }) }}
                    autoHighlight
                    handleHomeEndKeys
                    clearText='Limpar'
                    noOptionsText='Digite para pesquisar Serviços'
                    loading={state.smallLoading}
                    ChipProps={{ size: 'small' }}
                    filterSelectedOptions
                    onOpen={() => state.loaded_services.length === 0 && timeoutList('', `order/proposal/services/${state.item_type}/`, 'loaded_services', 'name', setState, true)}
                    onInputChange={(e, v) => { e?.type === 'change' && timeoutList(v, `order/proposal/services/${state.item_type}/`, 'loaded_services', 'name', setState, true) }}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            id={'autocompleteinput'}
                            type="text"
                            sx={{ backgroundColor: 'white' }}
                            label="Serviços"
                            size="small"
                            fullWidth={true}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {state.smallLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            }}
                        />
                    }
                />
            </Box>}
            {state.services.length > 0 &&
                <>
                    <List className='product-service-list'>
                        {state.services.map((service, index) => (
                            <Box className='product-service-list-wrapper'>
                                <ListItem className='nopadding' key={`service-listitem${service.id}`} title={service.label}>
                                    <Box className='simple-label'>
                                        <Box>{service.label}</Box>
                                        <Box className='single-line'></Box>
                                    </Box>
                                    <TextField
                                        size='small'
                                        label='Valor.'
                                        className='simple-value'
                                        value={service.value}
                                        inputProps={{
                                            maxLength: 13
                                        }}
                                        InputProps={{
                                            inputComponent: NumericFormatInput
                                        }}
                                        onChange={(e) => { service.value = e.target.value; setState({ services: [...state.services] }) }} />
                                </ListItem>
                                <Box sx={{ display: 'flex', gap: '1rem' }}>
                                    <FormControl fullWidth sx={{ minWidth: 120 }} size='small' >
                                        <InputLabel id="select-plagues-label">{item_types[state.item_type]}</InputLabel>
                                        <Select
                                            multiple
                                            max={1}
                                            labelId="select-plagues-label"
                                            id="select-plagues"
                                            label={item_types[state.item_type]}
                                            value={service.plagues ? service.plagues.map(e => e.id) : []}
                                            onChange={(e) => { service.plagues = service.data?.plagues_serialized.filter(each => e.target.value.includes(each.id)); setState({ services: [...state.services] }) }}
                                        >
                                            {(service.data?.plagues_serialized)?.map((each) =>
                                                <MenuItem value={each.id}>{capitalize(each.label)}</MenuItem>
                                            )}

                                        </Select>
                                    </FormControl>
                                </Box >
                                {service.plagues?.length > 0 &&
                                    <>
                                        <List className='product-service-list'>
                                            {service.plagues?.map((plague, index) => (
                                                <ListItem className='nopadding' key={`plagues-listitem${plague.id}`} title={plague.label}>
                                                    <Box className='simple-label'>
                                                        <Box>{plague.label}</Box>
                                                        <Box className='single-line'></Box>
                                                    </Box>
                                                    <FormControl sx={{ minWidth: 120 }} size='small' >

                                                        <InputLabel id="select-garantee-label-plague">Garantia</InputLabel>
                                                        <Select
                                                            labelId="select-garantee-label-plague"
                                                            id="select-garantee"
                                                            label="Garantia"
                                                            value={plague.data?.garantee || '0'}
                                                            onChange={(e) => { plague.data.garantee = e.target.value; setState({ services: [...state.services] }) }}
                                                        >
                                                            {state.garantee_list.map(([key, value]) =>
                                                                <MenuItem value={key}>{capitalize(value)}</MenuItem>
                                                            )}

                                                        </Select>
                                                    </FormControl>
                                                </ListItem>
                                            ))}

                                        </List>
                                    </>}
                                <Box sx={{ display: 'flex', gap: '1rem' }}>
                                    <Autocomplete
                                        multiple
                                        sx={{ flex: 1 }}
                                        id={'autocompletebox-products'}
                                        value={service.products}
                                        options={state.loaded_products.filter(each => !service.products?.map(e => e.id).includes(each.id))}
                                        onChange={(e, selectedProducts) => { service.products = deepCopy(selectedProducts); setState({ services: [...state.services] }) }}
                                        autoHighlight
                                        handleHomeEndKeys
                                        clearText='Limpar'
                                        noOptionsText='Digite para pesquisar Produtos'
                                        loading={state.smallLoading}
                                        ChipProps={{ size: 'small' }}
                                        filterSelectedOptions
                                        onOpen={() => state.loaded_products.length === 0 && timeoutList('', 'order/proposal/products/', 'loaded_products', 'name', setState)}
                                        onInputChange={(e, v) => { e?.type === 'change' && timeoutList(v, 'order/proposal/products/', 'loaded_products', 'name', setState) }}
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                id={'autocompleteinput'}
                                                type="text"
                                                sx={{ backgroundColor: 'white' }}
                                                label="Produtos"
                                                size="small"
                                                fullWidth={true}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <>
                                                            {state.smallLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                            {params.InputProps.endAdornment}
                                                        </>
                                                    ),
                                                }}
                                            />
                                        }
                                    />

                                </Box >
                                {service.products?.length > 0 &&
                                    <>
                                        <List className='product-service-list'>
                                            {service.products?.map((product, index) => (
                                                <>
                                                    <ListItem className='nopadding' key={`product-listitem${product.id}${service.id}`} title={product.label}>
                                                        <Box className='simple-label'>
                                                            <Box>{product.label} (Un: {product.data?.unity})</Box>
                                                            <Box className='single-line'></Box>
                                                        </Box>
                                                        <TextField
                                                            size='small'
                                                            label='Qtd.'
                                                            key={`product-quantity${product.id}${service.id}`}
                                                            className='simple-text'
                                                            value={product.value || ''}
                                                            inputProps={{
                                                                maxLength: 5
                                                            }}
                                                            onChange={(e) => { product.value = FloatOnly(e.target.value, 0, 999); setState({ services: [...state.services] }) }} />

                                                    </ListItem>
                                                    <TextField
                                                        fullWidth
                                                        label='Descrição do local a ser tratado'
                                                        value={product.locals}
                                                        key={`product-locals${product.id}${service.id}`}
                                                        size='small'
                                                        onChange={e => { product.locals = e.target.value; setState({ services: [...state.services] }) }}
                                                        inputProps={{
                                                            maxLength: 255
                                                        }}
                                                    />
                                                </>
                                            ))}

                                        </List>
                                    </>}
                                <TextField
                                    multiline
                                    maxRows={4}
                                    label='Procedimentos'
                                    value={service.procedure}
                                    size='small'
                                    onChange={e => { service.procedure = e.target.value; setState({ services: [...state.services] }) }}
                                    inputProps={{
                                        maxLength: 5000
                                    }}
                                />
                            </Box>

                        ))}


                    </List>
                </>}
        </>
    )
}

const initialize = async (setIntstate, recallData, setLoader, changedServices) => {
    setLoader(true)
    const res = await api.get(`order/serviceorder/${recallData}/products/`)
    if (res.status === 200) {
        setIntstate({ ...res.data, loaded: true, services: changedServices || res.data.services })
    } else {
        toast.error('Dados de produtos não encontrados.')
    }
    setLoader(false)

}

const ServiceSelection = ({ state, setState, item_types = {}, handleClose = () => { }, recallData, submit = () => { }, changedServices }) => {

    const { setLoader } = useContext(externalComponent)

    const [intstate, setIntstate] = useReducer(lockedReducer, {
        loaded_services: [],
        loaded_products: [],
        garantee_list: [],
        services: [],
        item_types: {},
        item_type: {},
        loaded: false,
        step: 1
    })

    useEffect(() => {
        if (recallData)
            initialize(setIntstate, recallData, setLoader, changedServices)
    }, [recallData, setLoader, changedServices])

    const validData = validateData(intstate)

    console.log(intstate)

    return (
        <>
            {!recallData ?
                <ServiceSelectionMain state={state} setState={setState} item_types={item_types} />
                :
                <>
                    {
                        intstate.loaded &&
                        <DefaultModal
                            handleClose={handleClose}
                            title='Alterar produtos'
                            content={
                                <ServiceSelectionMain state={intstate} setState={setIntstate} item_types={intstate.item_types} hideSelection={true} />
                            }
                            action={
                                <>
                                    <Tooltip title={validData}>
                                        <span>
                                            <ColorButton
                                                onClick={() => submit(intstate.services)}
                                                disabled={validData}>
                                                Salvar
                                            </ColorButton>
                                        </span>
                                        </Tooltip>
                                    </>
                            }

                        />
                    }
                                </>
                            }
        </>
    )
}

            export default ServiceSelection