import React, { useContext, useReducer } from 'react'
import { lockedReducer } from '../Utils/reducers'
import dayjs from 'dayjs'
import DefaultModal from '../Utils/DefaultModal'
import { MobileDatePicker } from '@mui/x-date-pickers'
import { number2dayweek } from '../Utils/dayjsptbrhelper'
import { externalComponent } from '../AppRoutes'
import { api } from '../../services/api'
import toast from 'react-hot-toast'
import ColorButton from '../Buttons/ColorButton'
import { defaultBackend, downloadFile } from '../../services/api'

const url = '/order/schedule/dayschedule/'

const submitSchedule =async (date, handleClose, setLoader) => {
    setLoader(true)
    const res = await api.post(url, {datetime__gte: date.format('YYYY-MM-DD 00:00Z'), datetime__lt:date.add(1, 'day').format('YYYY-MM-DD 00:00Z')})
    setLoader(false)
    if (res.status === 200){
        toast.success('Agenda gerada com sucesso!')
        await downloadFile(defaultBackend.replace('api/', '') + res.data + '/')
        //handleClose()
    } else {
        toast.error('Houve um erro para gera a agenda')
    }

}

const DayScheduleModal = ({ handleClose }) => {

    const {setLoader} = useContext(externalComponent)

    const [state, setState] = useReducer(lockedReducer, {
        date: dayjs()
    })

    return (
        <DefaultModal
            title='Agenda do dia'
            handleClose={handleClose}
            dialogProps={{maxWidth:'xs', fullWidth:false}}
            content={
                <>
                    <MobileDatePicker
                        size='small'
                        label='Data'
                        value={state.date}
                        format='DD/MM/YYYY'
                        sx={{ background: 'white' }}
                        onChange={(e) => { setState({ 'date': e }) }}
                        slotProps={{ textField: { size: 'small' } }}
                        dayOfWeekFormatter={(n, date) => number2dayweek[date.$W]}
                        ampm={false}
                    />
                </>
            }
            action={
                <>
                    <ColorButton onClick={() => { submitSchedule(state.date, handleClose, setLoader) }}
                        sx={{ height: '2.5rem' }}>
                        Imprimir agenda
                    </ColorButton>
                </>
            }
        />
    )
}

export default DayScheduleModal