import React, { useCallback, useContext, useEffect, useReducer, useState } from 'react'
import { lockedReducer } from '../../Utils/reducers'
import { api } from '../../../services/api'
import { getSession } from '../../../contexts/auth'
import { toast } from 'react-hot-toast'
import { Box, TextField } from '@mui/material'
import './styles.css'
import ColorButton from '../../Buttons/ColorButton'
import SaveIcon from '@mui/icons-material/Save';
import { externalComponent } from '../../AppRoutes'
import PasswordIcon from '@mui/icons-material/Password';
import ChangePassword from './change-password'
import Member from '../Member'
import uploadfilefunction from '../../Utils/uploadfilefunction'
import { PhoneMask } from '../../Utils/MaskedInput'
import ReplyIcon from '@mui/icons-material/Reply'

const Profile = () => {

    const { setLoader, isMobile } = useContext(externalComponent)

    const [showPasswordDialog, setShowPasswordDialog] = useState(false)

    const [state, setState] = useReducer(lockedReducer, {
        id: 0,
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        profile: {}
    })

    const [showMember, setShowMember] = useState(false)

    const user = getSession()

    const getProfile = useCallback(async () => {
        setLoader(true)
        const res = await api.get(`/myuser`)
        setLoader(false)
        if (res.status === 200) {
            setState(res.data)
            return
        }
        toast.error('Ocorreu um erro ao gerar os dados')

    }, [user.id, setLoader])


    useEffect(() => {
        getProfile()
    }, [getProfile])

    const saveData = useCallback(async (data) => {
        setLoader(true)
        if (data.profile.upload_file) {
            const res = await uploadfilefunction(data.profile.upload_file, `/member/uploadownsignature/`)
            if (res.status !== 200) {
                toast.error('Ocorreu um erro ao trocar a imagem')
                setLoader(false)
                return
            }
        }


        const res = await api.patch(`/users/change_user/`, data)
        setLoader(false)
        if (res.status === 200) {
            setState(res.data)
            toast.success('Dados salvos com sucesso')
            return
        }

        toast.error('Ocorreu um erro ao gerar os dados')

    }, [setLoader])

    const changePassword = async (newPassword) => {
        setLoader(true)
        const res = await api.post(`/users/change/`, { password: newPassword })
        setLoader(false)
        if (res.status === 200) {
            toast.success('Senha alterada com sucesso!')
            setShowPasswordDialog(false)
            return
        }
        else if (res.status === 400) {
            toast.error(res.data.join('\n'))
            return
        }
        toast.error('A senha não foi alterada pois ocorreu um erro.')
    }

    return (
        <>
            {showMember &&
                <Member handleClose={() => setShowMember(false)} data={state.profile} parent={state} setParent={setState} profileView={true} />
            }
            {showPasswordDialog && <ChangePassword ChangePassword={changePassword} handleClose={() => setShowPasswordDialog(false)} />}
            {state.id ?
                <>
                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                        {!isMobile && <ColorButton
                            className='profile-save-button'
                            onClick={() => window.backtobase()}
                        >
                            <Box className='flexbox'><ReplyIcon /> Voltar</Box>
                        </ColorButton>}
                        <ColorButton
                            className='profile-save-button'
                            onClick={() => saveData(state)}
                        >
                            <Box className='flexbox'><SaveIcon /> Salvar</Box>
                        </ColorButton>
                        <ColorButton
                            className='profile-save-button'
                            onClick={() => setShowPasswordDialog(true)}
                        >
                            <Box className='flexbox'><PasswordIcon /> Trocar senha</Box>
                        </ColorButton>
                    </Box>
                    <Box className='profile-overflow'>
                        <Box className='item-content-modal item-padding' >
                            <h3>Perfil</h3>
                            <Box className='item-profile-row'>
                                <TextField
                                    id="clientname"
                                    type="text"
                                    size='small'
                                    sx={{ backgroundColor: 'white' }}
                                    label="Nome"
                                    value={state.first_name}
                                    onChange={(e) => { setState({ 'first_name': e.target.value }) }}
                                    inputProps={{
                                        maxLength: 50
                                    }}
                                />

                                <TextField
                                    id="clientlastname"
                                    type="text"
                                    sx={{ backgroundColor: 'white' }}
                                    label="Sobrenome"
                                    size='small'
                                    value={state.last_name}
                                    onChange={(e) => { setState({ 'last_name': e.target.value }) }}
                                    inputProps={{
                                        maxLength: 50
                                    }}
                                />
                            </Box>
                            <Box className='item-profile-row'>
                                <TextField
                                    id="clientphone"
                                    type="text"
                                    size='small'
                                    sx={{ backgroundColor: 'white' }}
                                    label="Telefone"
                                    value={state.phone_number}
                                    onChange={(e) => { setState({ 'phone_number': e.target.value }) }}
                                    InputProps={{
                                        inputComponent: PhoneMask,
                                    }}
                                />

                                <TextField
                                    id="clientemail"
                                    type="email"
                                    size='small'
                                    sx={{ backgroundColor: 'white' }}
                                    label="E-mail"
                                    disabled
                                    value={state.email}
                                    onChange={(e) => { setState({ 'email': e.target.value }) }}
                                    inputProps={{
                                        maxLength: 100
                                    }}
                                />
                            </Box>
                            <ColorButton onClick={() => { setShowMember(true) }} >Ver dados de membro</ColorButton>
                            <Box className='item-profile-row-groups'>
                                <TextField
                                    id="groups"
                                    type="text"
                                    sx={{ backgroundColor: 'white' }}
                                    label="Grupos"
                                    disabled
                                    size='small'
                                    defaultValue={Object.keys(user.groups)?.map(each => each)?.join(', ')}
                                    fullWidth={true}
                                    multiline
                                    maxRows={10}
                                    InputProps={{
                                        disabled: true
                                    }}
                                />

                                <TextField
                                    multiline
                                    maxRows={10}
                                    id="groups"
                                    type="text"
                                    sx={{ backgroundColor: 'white' }}
                                    label="Permissões"
                                    size='small'
                                    defaultValue={Object.keys(user.permissions)?.map(each => each)?.join(', ')}
                                    InputProps={{
                                        disabled: true
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>

                </>
                :
                null}
        </>
    )
}

export default Profile