import React, { useReducer, useContext } from 'react'
import { lockedReducer } from '../../Utils/reducers'
import { api } from '../../../services/api'
import ColorButton from '../../Buttons/ColorButton';
import DefaultModal from '../../Utils/DefaultModal';
import toast from 'react-hot-toast'
import { Box, Tooltip, TextField, Autocomplete, FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import validateData from './validateData';
import { externalComponent } from '../../AppRoutes';
import timeoutList from '../../Utils/TimeoutList';
import CircularProgress from '@mui/material/CircularProgress';
import { capitalize } from '../../Utils/functions';

const url = '/product/'

const getTitle = (data) => {
    if (data?.id)
        return 'Editar'
    return 'Adicionar'
}

const submit = async (data, getData, setLoader, handleClose) => {

    setLoader(true)
    let res = null

    if (data.id)
        res = await api.patch(`${url}${data.id}/`, data)
    else
        res = await api.post(url, data)
    if (res.status === 201 || res.status === 200) {
        toast.success(`Produto ${res.status === 201 ? 'adicionado' : 'editado'} com sucesso`)
        getData()
        handleClose()
        setLoader(false)
        return
    }
    toast.error(`Produto não pôde ser ${!data.id ? 'adicionado' : 'editado.'} Motivo: ${res.data}`)
    setLoader(false)
}

const EditProductModal = ({ data, handleClose, reloadData, unitys }) => {

    const { setLoader } = useContext(externalComponent)

    const [state, setState] = useReducer(lockedReducer, {
        name: '',
        quim_group: '',
        active_principal: '',
        concentration: '',
        diluent: '',
        antidote: '',
        UN: '',
        ...data,
        id: data.id ? data.id : 0,
        equipment: data.equipment || '',
    })

    const [helpers, setHelpers] = useReducer(lockedReducer, {
        loaded_equipments: []
    })

    const validData = validateData(state)

    return (
        <>
            <DefaultModal
                title={
                    `${getTitle(state)} Produto`
                }
                handleClose={handleClose}
                content={
                    <>
                        <Box className='item-content-modal noborder' >
                            <Box className='item-profile-row'>
                                <TextField
                                    id="name"
                                    type="text"
                                    label="Nome"
                                    value={state.name}
                                    onChange={(e) => { setState({ 'name': e.target.value }) }}
                                    fullWidth={false}
                                    size='small'
                                    inputProps={{
                                        maxLength: 50
                                    }}
                                />

                            </Box>
                            <Box className='item-profile-row'>
                                <TextField
                                    id="quim_group"
                                    type="text"
                                    label="Grupo químico"
                                    size='small'
                                    value={state.quim_group}
                                    onChange={(e) => { setState({ 'quim_group': e.target.value }) }}
                                    fullWidth={false}
                                    inputProps={{
                                        maxLength: 100
                                    }}
                                />
                                <TextField
                                    id="active_principal"
                                    type="text"
                                    label="Princípio Ativo"
                                    size='small'
                                    value={state.active_principal}
                                    onChange={(e) => { setState({ 'active_principal': e.target.value }) }}
                                    fullWidth={false}
                                    inputProps={{
                                        maxLength: 100
                                    }}
                                />
                            </Box>
                            <Box className='item-profile-row'>
                                <TextField
                                    id="concentration"
                                    type="text"
                                    label="Concentração de uso"
                                    size='small'
                                    value={state.concentration}
                                    onChange={(e) => { setState({ 'concentration': e.target.value }) }}
                                    fullWidth={false}
                                    inputProps={{
                                        maxLength: 100
                                    }}
                                />
                                <TextField
                                    id="diluent"
                                    type="text"
                                    label="Diluente"
                                    size='small'
                                    value={state.diluent}
                                    onChange={(e) => { setState({ 'diluent': e.target.value }) }}
                                    fullWidth={false}
                                    inputProps={{
                                        maxLength: 100
                                    }}
                                />
                            </Box>
                            <Box className='item-profile-row'>
                                <TextField
                                    id="antidote"
                                    type="text"
                                    label="Antídoto"
                                    size='small'
                                    value={state.antidote}
                                    onChange={(e) => { setState({ 'antidote': e.target.value }) }}
                                    fullWidth={false}
                                    inputProps={{
                                        maxLength: 100
                                    }}
                                />
                            </Box>
                            <Box className='item-profile-row'>
                                <Autocomplete
                                    sx={{ flex: '.7 !important' }}
                                    id={'autocompletebox-equipments'}
                                    value={state.equipment}
                                    options={helpers.loaded_equipments.filter(each => !state.equipment)}
                                    onChange={(e, selectedEquipment) => {setState({ equipment: selectedEquipment }) }}
                                    autoHighlight
                                    handleHomeEndKeys
                                    clearText='Limpar'
                                    noOptionsText='Digite para pesquisar Equipamentos'
                                    loading={state.smallLoading}
                                    ChipProps={{ size: 'small' }}
                                    filterSelectedOptions
                                    onOpen={()=> helpers.loaded_equipments.length === 0 && timeoutList('', '/product/equipments/', 'loaded_equipments', 'name', setHelpers) }                  
                                    onInputChange={(e, v) => { e?.type === 'change' && timeoutList(v, '/product/equipments/', 'loaded_equipments', 'name', setHelpers) }}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            id={'autocompleteinput'}
                                            type="text"
                                            sx={{ backgroundColor: 'white' }}
                                            label="Equipamentos"
                                            size="small"
                                            fullWidth={true}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <>
                                                        {state.smallLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </>
                                                ),
                                            }}
                                        />
                                    }
                                />
                                <FormControl sx={{flex: '.3 !important' }} size='small' >
                                    <InputLabel id="select-unitys-label">Un</InputLabel>
                                    <Select
                                        labelId="select-unitys-label"
                                        id="select-unitys"
                                        label="Un"
                                        value={state.UN}
                                        onChange={(e) => { setState({ 'UN': e.target.value }) }}
                                    >
                                        {unitys.map(([key, value]) =>
                                            <MenuItem value={key}>{capitalize(value)}</MenuItem>
                                        )}

                                    </Select>
                                </FormControl>
                            </Box>

                        </Box>
                    </>
                }
                action={
                    <>
                        <Tooltip placement="left" title={validData}>
                            <Box>
                                <ColorButton
                                    onClick={() => submit(state, reloadData, setLoader, handleClose)}
                                    disabled={validData}
                                >Salvar
                                </ColorButton>
                            </Box>
                        </Tooltip>
                    </>
                }
            />
        </>
    )
}

export default EditProductModal