import React, { useContext, useEffect, useLayoutEffect, useMemo, useReducer } from 'react'
import { lockedReducer } from '../Utils/reducers'
import { createMonthArr, createWeekArr } from './dayarrays'
import dayjs from 'dayjs'
import { Box } from '@mui/material'
import './styles.css'
import { externalComponent } from '../AppRoutes'
import { api } from '../../services/api'
import { capitalize } from '../Utils/functions'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ColorButton from '../Buttons/ColorButton'
import WorkIcon from '@mui/icons-material/Work';
import EngineeringIcon from '@mui/icons-material/Engineering';
import TourIcon from '@mui/icons-material/Tour';
import 'dayjs/locale/pt-br'
import MonthCell from './month-cell'
import apptheme from '../theme/default'
import { getSession } from '../../contexts/auth'
import EditScheduleModal from '../Cockpit/Schedule/Edit-schedule-modal'
import EditProposalModal from '../Cockpit/Proposal/Edit-proposal-modal'
import EditServiceOrderModal from '../Cockpit/ServiceOrder/Edit-serviceorder-modal'
import DayScheduleModal from './dayschedulemodal'

dayjs.locale('pt-BR')

const weekdays = {
    'DOM': 'Domingo',
    'SEG': 'Segunda',
    'TER': 'Terça',
    'QUA': 'Quarta',
    'QUI': 'Quinta',
    'SEX': 'Sexta',
    'SAB': 'Sábado',
}

export const avatars = {
    'schedule': (<TourIcon />),
    'proposal': (<WorkIcon />),
    'serviceorder': (<EngineeringIcon />)
}

export const colors = {
    'OPEN': '#ffa500',
    'SCHEDULED': '#0d6efd',
    'DONE': '#24a024',
    'CANCELLED': '#dc3545',
}

export const translations = {
    'OPEN': 'Em aberto',
    'SCHEDULED': 'Agendado',
    'DONE': 'Concluído',
    'CANCELLED': 'Cancelado',
    'schedule': 'Agendamento de visita',
    'proposal': 'Proposta',
    'serviceorder': 'Ordem de serviço'
}

const getData = async (arr, setState, setLoader) => {
    setLoader(true)


    const querysearch = new URLSearchParams();
    querysearch.append('datetime__gte', arr[0].format('YYYY-MM-DD HH:mmZ'))
    querysearch.append('datetime__lt', arr[arr.length - 1].format('YYYY-MM-DD HH:mmZ'))
    const schedule_promise = api.get('/order/schedule/?' + querysearch.toString())
    const proposal_promise = api.get('/order/proposal/?' + querysearch.toString())
    const serviceorder_promise = api.get('/order/serviceorder/?' + querysearch.toString())

    const [schedule, proposal, serviceorder] = await Promise.all([schedule_promise, proposal_promise, serviceorder_promise])
    setLoader(false)

    const respArr = []

    if (schedule.status === 200) {
        respArr.push(...schedule.data.results.map(each => ({ ...each, type: 'schedule', format_datetime: dayjs(each.datetime).format('YYYYMMDD') })))
    }
    if (proposal.status === 200) {
        respArr.push(...proposal.data.results.map(each => ({ ...each, type: 'proposal', format_datetime: dayjs(each.datetime).format('YYYYMMDD') })))
    }
    if (serviceorder.status === 200) {
        respArr.push(...serviceorder.data.results.map(each => ({ ...each, type: 'serviceorder', format_datetime: dayjs(each.datetime).format('YYYYMMDD') })))
    }

    setState({ arr: arr, data: respArr })
}

const initialize = (selectedDay, view, setState, setLoader) => {
    let arr = []
    if (view === 'month')
        arr = createMonthArr(selectedDay.$M, selectedDay.$y)
    else
        arr = createWeekArr(selectedDay.$D, selectedDay.$M, selectedDay.$y)
    getData(arr, setState, setLoader)
}

const initialFilters = {
    ...Object.fromEntries(Object.entries(avatars).map(([key, value]) => [key, { icon: value, active: true }])),
    ...Object.fromEntries(Object.entries(colors).map(([key, value]) => [key, { color: value, active: true }]))
}

const Calendar = ({ setParent }) => {

    const user = getSession()

    const { setLoader } = useContext(externalComponent)

    const [state, setState] = useReducer(lockedReducer, {
        selectedDay: dayjs(),
        view: 'month',
        arr: [],
        data: [],
        showFilters: false,
        editSchedule: null,
        editProposal: null,
        editServiceOrder: null,
        showDaySchedule: false
    })

    const [filters, setFilters] = useReducer(lockedReducer, {
       ...initialFilters
    })

    const activeFilters = useMemo(() => Object.entries(filters).filter((each) => each[1].active).map(each => each[0]), [filters])

    useLayoutEffect(() => {
        initialize(state.selectedDay, state.view, setState, setLoader)
    }, [state.selectedDay, state.view, setLoader])

    useLayoutEffect(() => {
        const initialFilter = localStorage.getItem('calendar-filter')
        if (initialFilter){
            const newInitialFilter = JSON.parse(initialFilter)
            const newfilters = Object.fromEntries(Object.entries(initialFilters).map(([key, value]) => [[key], {...value, active: newInitialFilter.includes(key)}]))
            setFilters(newfilters)
        }
    }, [])


    useEffect(() => {
        document.getElementsByClassName('today')[0]?.scrollIntoView()
    }, [state.arr])

    const weekdays_list = useMemo(() => Object.entries(weekdays), [])

    return (
        <>
            {state.editSchedule &&
                <EditScheduleModal
                    data={state.editSchedule}
                    handleClose={() => setState({ editSchedule: null })}
                    reloadData={() => initialize(state.selectedDay, state.view, setState, setLoader)}
                    openProposal={forward => setState({ editSchedule: null, editProposal: forward })}
                />
            }
            {state.editProposal &&
                <EditProposalModal
                    data={state.editProposal}
                    handleClose={() => setState({ editProposal: null })}
                    reloadData={() => initialize(state.selectedDay, state.view, setState, setLoader)}
                    openOS={forward => setState({ editProposal: null, editServiceOrder: forward })}
                />
            }
            {state.editServiceOrder &&
                <EditServiceOrderModal
                    data={state.editServiceOrder}
                    handleClose={() => setState({ editServiceOrder: null })}
                    reloadData={() => initialize(state.selectedDay, state.view, setState, setLoader)}
                    openOS={forward => setState({ editServiceOrder: forward })}
                />
            }
            {state.showDaySchedule &&
                <DayScheduleModal handleClose={() => setState({ showDaySchedule: false })} />
            }

            <Box className='calendar-main-frame'>
                <Box className='left' sx={{ display: 'flex', gap: '1rem' }}>
                    <ColorButton disabled={!user.permissions.add_schedule}
                        onClick={() => setState({ editSchedule: {} })}>
                        Nova visita
                    </ColorButton>
                    <ColorButton
                        reverse={true}
                        border={'1px solid #d0d0d0'}
                        onClick={() => setState({ showDaySchedule: true })}>
                        Agenda do dia
                    </ColorButton>
                    <ColorButton
                        reverse={!state.showFilters}
                        border={'1px solid #d0d0d0'}
                        onClick={() => setState({ showFilters: !state.showFilters })}>
                        Ver Filtros
                    </ColorButton>
                </Box>
                {state.showFilters &&
                    <Box>
                        <h6>Filtros</h6>
                        <Box className='calendar-inlineflex'>
                            {Object.entries(filters).map(([key, value]) =>
                                <ColorButton
                                    hColor={apptheme.quaternaryColor}
                                    startIcon={value.icon || <Box className='inlineicon' sx={{ backgroundColor: value.color }}></Box>}
                                    reverse={!value.active}
                                    onClick={() => {
                                        const newFilter = { [key]: { ...value, active: !value.active } }
                                        setFilters(newFilter);
                                        const filterString = Object.entries({...filters, ...newFilter}).filter(([,value]) => value.active).map(([key,]) => key)
                                        localStorage.setItem('calendar-filter', JSON.stringify(filterString))
                                    }}>
                                    {translations[key]}
                                </ColorButton>
                            )}
                        </Box>
                    </Box>}
                <Box className='calendar-wrapper'>
                    {state.view === 'month' &&
                        <>
                            <Box className='select-month-row'>
                                <ColorButton onClick={() => setState({ selectedDay: state.selectedDay.subtract(1, 'month') })}><ArrowBackIcon /></ColorButton>
                                <Box>{capitalize(state.selectedDay.format('MMMM YYYY'))}</Box>
                                <ColorButton onClick={() => setState({ selectedDay: state.selectedDay.add(1, 'month') })}><ArrowForwardIcon /></ColorButton>
                            </Box>
                            <Box className='month-list-wrapper'>
                                <Box className='month-list-header'>
                                    {weekdays_list.map((each) =>
                                        <Box className='month-header'>{each[0]}</Box>
                                    )}
                                </Box>
                                <Box className='month-list'>
                                    {state.arr.map(each =>
                                        <Box className={`month-cell ${each.$M !== state.selectedDay.$M ? 'otherday' : ''} ${each.format('YYYYMMDD') === dayjs().format('YYYYMMDD') ? 'today' : ''}`} >
                                            <Box className='month-items'>
                                                {each.format('DD')}
                                                <MonthCell openItem={setState}>
                                                    {state.data
                                                        .filter(order => activeFilters.includes(order.type) && activeFilters.includes(order.state))
                                                        .filter(order => order.format_datetime === each.format('YYYYMMDD'))
                                                        .toSorted((x, y) => dayjs(x.datetime) - dayjs(y.datetime))}
                                                </MonthCell>
                                            </Box>
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                        </>
                    }
                </Box>
            </Box>
        </>
    )
}

export default Calendar