import React, { useContext, useEffect, useMemo, useReducer } from "react";

import { Box, IconButton, Tooltip } from "@mui/material";

import { api } from "../../../services/api";
import toast from "react-hot-toast";
import { getSession } from "../../../contexts/auth";
import { lockedReducer } from "../../Utils/reducers";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ConfirmationDialog from "../../Utils/ConfirmationDialog";
import { externalComponent } from "../../AppRoutes";
import EditPlagueModal from "./Edit-plague-modal";
import Table, { TableHook } from "../../Table";
import { ResponsiveActionBox } from "../../Table/mobileTable";

const url = "/plague/";

const submitDelete = async (data, setState, getData, setLoader) => {
  setLoader(true);
  const res = await api.delete(`${url}${data.id}/`);
  setLoader(false);
  if (res.status === 204) {
    toast.success("Deletado com sucesso");
    setState({ deleteRow: false });
    getData();
    return;
  }
  console.error("Falha ao deletar");
};

const columns = (setState, permissions) => {
  return [
    {
      field: "identifier",
      headerName: "Identificador",
      flex: 1,
      filterType: "number",
    },
    {
      field: "code",
      headerName: "Código",
      flex: 1,
      filterType: "number",
    },
    { field: "name", headerName: "Nome", flex: 1, filterType: "string" },
    {
      field: "actions",
      headerName: "Ações",
      filterable: false,
      renderCell: (props) => {
        return (
            <ResponsiveActionBox>
            {permissions.change_plague && (
              <Tooltip title="Editar item">
                <IconButton onClick={() => setState({ editRow: props.row })}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}
            {permissions.delete_plague && (
              <Tooltip title="Excluir item">
                <IconButton onClick={() => setState({ deleteRow: props.row })}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
          </ResponsiveActionBox>
        );
      },
    },
  ];
};

const loadItems = async (setState) => {
  const res = await api.get(url+"items/")
  if (res.status === 200){
    setState({items: res.data})
  }
}

const Plague = () => {
  const { setLoader } = useContext(externalComponent);

  const user = getSession();

  const [state, setState] = useReducer(lockedReducer, {
    rows: [],
    editRow: null,
    deleteRow: null,
    items: {}
  });

  const [table, reloadData] = TableHook();

  useEffect(()=>{
    loadItems(setState)
  }, [])

  return (
    <>
      {state.deleteRow && (
        <ConfirmationDialog
          content={`Deseja excluir o item ${state.deleteRow.name} - ${state.deleteRow.identifier}?`}
          handleClose={() => setState({ deleteRow: null })}
          onConfirm={() =>
            submitDelete(state.deleteRow, setState, reloadData, setLoader)
          }
        />
      )}
      {state.editRow && (
        <EditPlagueModal
          data={state.editRow}
          handleClose={() => setState({ editRow: null })}
          reloadData={reloadData}
          items={state.items}
        />
      )}
      <Box className="information-table">
        <Box className="user-table">
          <Table
            rows={state.rows}
            columns={useMemo(
              () => columns(setState, user.permissions),
              [user.permissions]
            )}
            pagination
            density="compact"
            slots={{
              returnComponent: ()=> window.backtobase(),
              addComponent: () =>
                user.permissions.add_service ? setState({ editRow: {} }) : null,
              filterComponent: true,
              columnComponent: true,
            }}
            toolbar
            getURL={url}
            updateRows={(rows) => setState({ rows: rows })}
            innerRef={table}
            mobile={true}
            mobileProps={{
              title: ["document"],
              secondary: [["name"]],
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default Plague;
