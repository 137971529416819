import React, { useContext, useEffect, useReducer, useMemo } from 'react'

import { Box, IconButton, Tooltip } from '@mui/material'
import { api } from '../../../services/api';
import toast from 'react-hot-toast'
import { getSession } from '../../../contexts/auth';
import { lockedReducer } from '../../Utils/reducers';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ConfirmationDialog from '../../Utils/ConfirmationDialog';
import { externalComponent } from '../../AppRoutes';
import EditProposalModal from './Edit-proposal-modal';
import dayjs from 'dayjs';
import Table, { TableHook } from '../../Table';
import { defaultChoices } from '../Schedule';
import { maskedTable } from '../../Utils/MaskedInput';
import { ResponsiveActionBox } from '../../Table/mobileTable'

const url = '/order/proposal/'

const submitDelete = async (data, setState, getData, setLoader) => {
    setLoader(true)
    const res = await api.delete(`${url}${data.id}/`)
    setLoader(false)
    if (res.status === 204) {
        toast.success('Deletado com sucesso')
        setState({ deleteRow: false })
        getData()
        return
    }
    console.error('Falha ao deletar')

}

const columns = (setState, permissions) => {
    return [
        {
            field: 'identifier', headerName: 'Identificador', flex: 1, filterType: 'number'
        },
        {
            field: 'client_name', headerName: 'Cliente', flex: 1, filterType: 'string'
        },
        {
            field: 'created_at', headerName: 'Data de criação', flex: 1, filterType: 'daterange',
            valueGetter: ({ value }) => dayjs(value).toDate().toLocaleString()
        },
        {
            field: 'datetime', headerName: 'Data de visita', flex: 1, filterType: 'daterange',
            valueGetter: ({ value }) => value ? dayjs(value).toDate().toLocaleString() : ''
        },
        { field: 'state', headerName: 'Status', flex: 1, filterType: 'choices', choices:defaultChoices,
            valueGetter: ({row}) => row.status
        },
        {
            field: 'total_value', headerName: 'Valor', flex: 1, filterType: 'number',
            valueGetter: ({value}) => {
                return maskedTable['value'](value)
            }
        },
        {
            field: 'actions', headerName: 'Ações', filterable: false,
            renderCell: (props) => {
                return (
                    <ResponsiveActionBox>
                        {permissions.change_proposal && <Tooltip title='Editar proposta'> <IconButton onClick={() => { setState({ editRow: props.row }) }}><EditIcon /></IconButton></Tooltip>}
                        {permissions.delete_proposal && <Tooltip title='Excluir proposta'> <IconButton onClick={() => { setState({ deleteRow: props.row }) }}><DeleteIcon /></IconButton></Tooltip>}
                    </ResponsiveActionBox>
                )
            }
        }
    ]
}

const getItemTypes = async (setState) => {
    const res = await api.get(url + 'item_types/')
    if (res.status === 200) setState({item_types: res.data})
}

const Proposal = ({ parent, setParent }) => {

    const { setLoader } = useContext(externalComponent)

    const user = getSession()

    const [state, setState] = useReducer(lockedReducer, {
        rows: [],
        loading: false,
        editRow: null,
        deleteRow: null,
        item_types: {}
    })

    useEffect(() => {

        const final_state = {}

        // if (!state.item_types){
        //     const res = await api.get(url+'item_types/')
        //     if (res.status === 200) final_state.item_types = res.data
        // }
        getItemTypes(setState)

        if (parent.selectedForward) {
            final_state.editRow = parent.selectedForward
            setParent({ selectedForward: null })
        }

        setState(final_state)

    }, [setLoader, parent.selectedForward, setParent])

    const [table, reloadData] = TableHook()

    return (
        <>
            {state.deleteRow &&
                <ConfirmationDialog
                    content={`Deseja excluir a proposta ${state.deleteRow.identifier}?`}
                    handleClose={() => setState({ deleteRow: null })}
                    onConfirm={() => submitDelete(state.deleteRow, setState, reloadData, setLoader)}
                />
            }
            {state.editRow &&
                <EditProposalModal
                    data={state.editRow}
                    handleClose={() => setState({ editRow: null })}
                    reloadData={reloadData}
                    openOS={forward => setParent({ position: 'order', selectedForward: forward })}
                    item_types={state.item_types}
                />
            }
            <Box className='information-table'>
                <Box className='user-table'>
                    <Table
                        rows={state.rows}
                        columns={useMemo(()=> columns(setState, user.permissions), [user.permissions])}
                        pagination
                        density='compact'
                        slots={{
                            addComponent: user.permissions.add_proposal ? () => setState({ editRow: {} }) : null,
                            filterComponent: true,
                            columnComponent: true
                        }}
                        toolbar
                        getRowClassName={(params) => `dddrow--${params.row.state}`}
                        getURL={url}
                        updateRows={(rows) => setState({'rows': rows})}
                        innerRef={table}
                        mobile={true}
                        mobileProps={
                            {
                                title: ['client_name'],
                                secondary: [['created_at'], ['datetime'], ['total_value']],
                            }
                        }
                    />
                </Box>
            </Box>
        </>
    )
}

export default Proposal