import React, { useReducer, useContext, useEffect } from 'react'
import { lockedReducer } from '../../Utils/reducers'
import { api, downloadFile } from '../../../services/api'
import ColorButton from '../../Buttons/ColorButton';
import DefaultModal from '../../Utils/DefaultModal';
import toast from 'react-hot-toast'
import {
    Box, Tooltip, TextField, Autocomplete, Stepper, Step, StepLabel, List, ListItem, ListItemText, ListItemButton
    , FormControl, InputLabel, Select, MenuItem
} from '@mui/material'
import validateData from './validateData';
import { externalComponent } from '../../AppRoutes';
import timeoutList from '../../Utils/TimeoutList';
import { getSession } from '../../../contexts/auth';
import CircularProgress from '@mui/material/CircularProgress';
import apptheme from '../../theme/default';
import ConfirmationDialog from '../../Utils/ConfirmationDialog';
import { defaultBackend } from '../../../services/api';
import { maskedTable } from '../../Utils/MaskedInput';
import { generateReport } from '../Schedule/Edit-schedule-modal';
import { capitalize } from '../../Utils/functions';
import { NumericFormatInput } from '../../Utils/MaskedInput';
import EditClientModal from '../../Clients/Edit-client-modal';
import ServiceSelection from './serviceselection';

const url = '/order/proposal/'

const getTitle = (data) => {
    if (data?.id)
        return 'Editar'
    return 'Adicionar'
}

const getGarantee = async (setState) => {
    const garantee = api.get(url + 'garantee/')
    const validity = api.get(url + 'validity/')
    const [garantee_data, validity_data] = await Promise.all([garantee, validity])
    const response = {}
    if (garantee_data.status === 200)
        response.garantee_list = garantee_data.data
    if (validity_data.status === 200)
        response.validity_list = validity_data.data

    setState(response)
}

const loadProposalRow = async (id, setState, setLoader) => {
    setLoader(true)
    const res = await api.get(url + `${id}/`)
    if (res.status === 200) {
        const item_type = res.data.services[0]?.data?.documents_json[0]?.item_type || ''
        setState({ ...res.data, step: 3, loaded: true, saved: true, item_type: item_type })
    }
    setLoader(false)
}

const submit = async (data, getData, setLoader, setState) => {

    setLoader(true)
    let res = null

    if (data.id)
        res = await api.patch(`${url}${data.id}/`, data)
    else
        res = await api.post(url, data)
    if (res.status === 201 || res.status === 200) {
        toast.success(`Proposta ${res.status === 201 ? 'adicionada' : 'editada'} com sucesso`)
        getData()
        setState({ ...res.data, step: 3 })
        setLoader(false)
        return
    }
    toast.error(`Proposta não pôde ser ${!data.id ? 'adicionada' : 'editada.'} Motivo: ${res.data}`)
    setLoader(false)
}

const initOS = async (state, openOS) => {
    openOS({ client: state.client, client_name: state.client_name, order_id: state.order.id, order: state.order })
}

export const generateProposal = async (id, setLoader) => {
    setLoader(true)
    const res = await api.post(url + `${id}/generate/`)
    if (res.status === 200) {
        await downloadFile(defaultBackend.replace('api/', '') + res.data + '/')
    }
    setLoader(false)
}

const cancelProposal = async (state, setState, setLoader, getData, message = '') => {
    setLoader(true)
    const res = await api.post(url + `${state.id}/cancel/`, { message: message })
    if (res.status === 200) {
        toast.success(`Proposta cancelada com sucesso`)
        getData()
        setState({ ...res.data, step: 3, loaded: true })
    }
    setLoader(false)
}


const generateSteps = (state, setState, user, setLoader, item_types = {}) => {

    return [
        {
            label: 'Proposta', component: (
                <>
                    {((state.client?.label || state.client_name) && (state.schedule_responsable?.label || state.seller_name)) ?
                        <List className='proposal-resume-list'>
                            <ListItemButton><ListItemText primary='Cliente' secondary={state.client?.label || state.client_name} /></ListItemButton>
                            <ListItemButton><ListItemText primary='Vendedor' secondary={state.schedule_responsable?.label || state.seller_name} /></ListItemButton>
                        </List>
                        : <>
                            <Box className='item-profile-row' sx={{ flexDirection: 'column' }}>
                                <Autocomplete
                                    disabled={state.state !== ''}
                                    sx={{ flex: 1 }}
                                    id={'autocompletebox-client'}
                                    value={state.new_client}
                                    options={state.loaded_clients}
                                    onChange={(e, selectedClient) => { setState({ new_client: selectedClient }) }}
                                    autoHighlight
                                    handleHomeEndKeys
                                    clearText='Limpar'
                                    noOptionsText='Digite para pesquisar clientes'
                                    loading={state.smallLoading}
                                    onOpen={() => state.loaded_clients.length === 0 && timeoutList('', 'order/schedule/client/', 'loaded_clients', 'name', setState)}
                                    onInputChange={(e, v) => { e?.type === 'change' && timeoutList(v, 'order/schedule/client/', 'loaded_clients', 'name', setState) }}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            id={'autocompleteinput'}
                                            type="text"
                                            sx={{ backgroundColor: 'white' }}
                                            label="Cliente"
                                            size="small"
                                            fullWidth={true}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <>
                                                        {state.smallLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </>
                                                ),
                                            }}
                                        />
                                    }
                                />
                                <Box className='item-content-modal noborder nopadding limitedscroll' >
                                    <EditClientModal
                                        key={`client-modal-key${state.new_client?.data?.id}${state?.edit}`}
                                        data={state.new_client?.data}
                                        readOnly
                                        handleClose={() => { }}
                                        reloadData={() => { }}
                                    />
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', gap: '1rem' }}>
                                <Autocomplete
                                    sx={{ flex: 1 }}
                                    id={'autocompletebox-seller'}
                                    value={state.new_seller}
                                    options={state.loaded_sellers.filter(each => state.new_seller?.id !== each.id)}
                                    onChange={(e, selectedseller) => { setState({ new_seller: selectedseller }) }}
                                    autoHighlight
                                    handleHomeEndKeys
                                    clearText='Limpar'
                                    noOptionsText='Digite para pesquisar Vendedor'
                                    onOpen={() => state.loaded_sellers.length === 0 && timeoutList('', 'order/schedule/responsable/', 'loaded_sellers', 'first_name', setState)}
                                    loading={state.smallLoading}
                                    ChipProps={{ size: 'small' }}
                                    filterSelectedOptions
                                    onInputChange={(e, v) => { e?.type === 'change' && timeoutList(v, 'order/schedule/responsable/', 'loaded_sellers', 'first_name', setState) }}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            id={'autocompleteinput'}
                                            type="text"
                                            sx={{ backgroundColor: 'white' }}
                                            label="Vendedor"
                                            size="small"
                                            fullWidth={true}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <>
                                                        {state.smallLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </>
                                                ),
                                            }}
                                        />
                                    }
                                />
                            </Box >
                        </>
                    }
                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                        <Autocomplete
                            sx={{ flex: 1 }}
                            id={'autocompletebox-responsable'}
                            value={state.responsable}
                            options={state.loaded_responsable.filter(each => state.responsable?.id !== each.id)}
                            onChange={(e, selectedResponsable) => { setState({ responsable: selectedResponsable }) }}
                            autoHighlight
                            handleHomeEndKeys
                            clearText='Limpar'
                            noOptionsText='Digite para pesquisar Avaliadores'
                            loading={state.smallLoading}
                            ChipProps={{ size: 'small' }}
                            filterSelectedOptions
                            onOpen={() => state.loaded_responsable.length === 0 && timeoutList('', 'order/proposal/responsable/', 'loaded_responsable', 'first_name', setState)}
                            onInputChange={(e, v) => { e?.type === 'change' && timeoutList(v, 'order/proposal/responsable/', 'loaded_responsable', 'first_name', setState) }}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    id={'autocompleteinput'}
                                    type="text"
                                    sx={{ backgroundColor: 'white' }}
                                    label="Avaliador"
                                    size="small"
                                    fullWidth={true}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {state.smallLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </>
                                        ),
                                    }}
                                />
                            }
                        />
                    </Box >
                </>
            )
        },
        {
            label: 'Serviços', component: (
                <ServiceSelection state={state} setState={setState} item_types={item_types}/>
            )
        },
        {
            label: 'Pagamento', component: (
                <>
                    <List className='proposal-resume-list'>
                        <ListItemButton><ListItemText primary='Valor total da proposta' secondary={maskedTable['value'](state.services.map(each => Number(each.value)).reduce((a, b) => a + b, 0))} /></ListItemButton>
                    </List>
                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                        <Autocomplete
                            sx={{ flex: 1 }}
                            id={'autocompletebox-payments'}
                            value={state.payment}
                            options={state.loaded_payment.filter(each => state.payment?.id !== each.id)}
                            onChange={(e, selectedPayments) => { setState({ payment: selectedPayments, payment_data: { ...state.payment_data, entry_value: '' } }) }}
                            autoHighlight
                            handleHomeEndKeys
                            clearText='Limpar'
                            noOptionsText='Digite para pesquisar formas de pagamento'
                            loading={state.smallLoading}
                            ChipProps={{ size: 'small' }}
                            filterSelectedOptions
                            onOpen={() => state.loaded_payment.length === 0 && timeoutList('', 'order/proposal/payments/', 'loaded_payment', 'name', setState)}
                            onInputChange={(e, v) => { e?.type === 'change' && timeoutList(v, 'order/proposal/payments/', 'loaded_payment', 'name', setState) }}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    id={'autocompleteinput'}
                                    type="text"
                                    sx={{ backgroundColor: 'white' }}
                                    label="Forma de pagamento"
                                    size="small"
                                    fullWidth={true}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {state.smallLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </>
                                        ),
                                    }}
                                />
                            }
                        />
                    </Box>
                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                        <TextField
                            label='Desconto'
                            size='small'
                            value={state.payment_data.discount || ''}
                            onChange={(e) => setState({ payment_data: { ...state.payment_data, discount: e.target.value } })}
                            inputProps={{ maxLength: 13 }}
                            InputProps={{
                                inputComponent: NumericFormatInput
                            }}
                        />
                        <TextField
                            label='Acréscimo'
                            size='small'
                            value={state.payment_data.addition || ''}
                            onChange={(e) => setState({ payment_data: { ...state.payment_data, addition: e.target.value } })}
                            inputProps={{ maxLength: 13 }}
                            InputProps={{
                                inputComponent: NumericFormatInput
                            }}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                        <TextField
                            disabled={!state.payment?.data?.first_parcel}
                            label='Entrada'
                            size='small'
                            value={state.payment_data?.entry_value || ''}
                            onChange={(e) => setState({ payment_data: { ...state.payment_data, entry_value: e.target.value } })}
                            inputProps={{ maxLength: 13 }}
                            InputProps={{
                                inputComponent: NumericFormatInput
                            }}
                        />
                        <FormControl sx={{ minWidth: 200, background: 'white' }} size='small' >
                            <InputLabel id="select-parcels-label">Parcelas</InputLabel>
                            <Select
                                labelId="select-parcels-label"
                                id="select-parcels"
                                label="Parcelas"
                                disabled={state.payment?.data?.parcel_fix}
                                value={state.payment?.data?.parcel_fix ? state.payment?.data?.parcel_count : state.payment_data?.parcels || ''}
                                onChange={(e) => { setState({ payment_data: { ...state.payment_data, parcels: e.target.value } }) }}
                            >
                                <MenuItem value={''}>Selecione *</MenuItem>

                                {Array.from({ length: state.payment?.data?.parcel_count || 0 }, (value, index) => index + 1)?.map(each =>
                                    <MenuItem value={each}>{each}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl sx={{ minWidth: 200, background: 'white' }} size='small' >
                            <InputLabel id="select-garantee-label">Validade da proposta</InputLabel>
                            <Select
                                labelId="select-garantee-label"
                                id="select-garantee"
                                label="Validade da proposta"
                                value={state.garantee}
                                onChange={(e) => { setState({ 'garantee': e.target.value }) }}
                            >
                                <MenuItem value={''}>Selecione *</MenuItem>
                                {state.validity_list.map(([key, value]) =>
                                    <MenuItem value={key}>{capitalize(value)}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Box>
                    <TextField
                        multiline
                        maxRows={4}
                        size='small'
                        label={`Observação`}
                        className='simple-text'
                        value={state.observations}
                        onChange={(e) => { setState({ observations: e.target.value }) }}
                        inputProps={{
                            maxLength: 1000
                        }}
                    />
                </>
            )
        },
        {
            label: 'Ações', component: (
                <>
                    {(user.permissions.view_schedule && state.order?.schedule) && <ColorButton
                        onClick={() => generateReport(state.order.schedule, setLoader)}
                    >
                        Ver Visita
                    </ColorButton>}
                    {(state.state !== '' && user.permissions.view_proposal) && <ColorButton
                        onClick={() => generateProposal(state.id, setLoader)}
                    >
                        Ver proposta
                    </ColorButton>}
                    <List className='proposal-resume-list'>
                        <ListItemButton><ListItemText primary='Cliente' secondary={state.client?.label || state.client_name || state.new_client?.label} /></ListItemButton>
                        <Box sx={{ display: 'flex' }}>
                            <ListItemButton><ListItemText primary='Vendedor' secondary={state.schedule_responsable?.label || state.seller_name || state.new_seller?.label} /></ListItemButton>
                            <ListItemButton className='flexunset'><ListItemText primary='Responsável técnico' secondary={state.responsable?.label} /></ListItemButton>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Box sx={{ display: 'flex', flex: 1 }}>
                                <ListItemButton>
                                    <ListItemText primary='Subtotal'
                                        secondary={
                                            maskedTable['value'](
                                                state.services.map(each => Number(each.value)).reduce((a, b) => a + b, 0)
                                            )}
                                    />
                                </ListItemButton>
                                <ListItemButton>
                                    <ListItemText primary='Desconto'
                                        secondary={
                                            maskedTable['value'](
                                                Number(state.payment_data?.discount || 0)
                                            )}
                                    />
                                </ListItemButton>
                                <ListItemButton>
                                    <ListItemText primary='Acréscimo'
                                        secondary={
                                            maskedTable['value'](
                                                Number(state.payment_data?.addition || 0)
                                            )}
                                    />
                                </ListItemButton>
                            </Box>
                            <ListItemButton className='flexunset'>
                                <ListItemText primary='Valor Total'
                                    primaryTypographyProps={{ fontSize: '1.3rem', fontWeight: 'bold' }}
                                    secondaryTypographyProps={{ fontSize: '1.3rem', fontWeight: 'bold' }}
                                    secondary={
                                        maskedTable['value'](
                                            state.services.map(each => Number(each.value)).reduce((a, b) => a + b, 0)
                                            + Number(state.payment_data?.addition || 0)
                                            - Number(state.payment_data?.discount || 0)
                                        )}
                                />
                            </ListItemButton>
                        </Box>
                        <Box sx={{ display: 'flex' }}>
                            <ListItemButton><ListItemText primary='Validade' secondary={`${state.garantee} dias`} /></ListItemButton>
                            <ListItemButton sx={{ flex: '2 !important' }}><ListItemText primary='Forma de pagamento' secondary={`${state.payment?.label}`} /></ListItemButton>
                            <ListItemButton><ListItemText primary='Entrada' secondary={`${maskedTable['value'](Number(state.payment_data?.entry_value))}`} /></ListItemButton>
                            <ListItemButton className='flexunset'>
                                <ListItemText primary='Parcelas'
                                    secondary={`${state.payment_data?.parcels || state.payment?.data?.parcel_count || 1}`}
                                />
                            </ListItemButton>
                        </Box>

                        <ListItemButton>
                            <ListItemText
                                primary='Serviços'
                                secondary={(<List>
                                    {state.services.map(each => (
                                        <Box className='nopadding' sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <ListItem className='nopadding'>{each.label}</ListItem>
                                            <ListItem sx={{ flex: 1, whiteSpace: 'pre' }} className='nopadding'> {maskedTable['value'](each.value)}</ListItem>
                                        </Box>))}
                                </List>)} />
                        </ListItemButton>
                        <ListItemButton>
                            <ListItemText
                                primary='Observações'
                                secondary={state.observations} />
                        </ListItemButton>
                        {state.state === 'CANCELLED' && <ListItemButton><ListItemText primary='Motivo do cancelamento' secondary={state.cancel_register} /></ListItemButton>}
                    </List>
                </>
            )
        },
    ]
}

const EditProposalModal = ({ data, handleClose, reloadData, openOS, item_types = {} }) => {

    const user = getSession()

    const { setLoader } = useContext(externalComponent)

    const [state, setState] = useReducer(lockedReducer, {
        id: 0,
        order: null,
        order_id: null,
        client: {},
        loaded: false,
        services: [],
        observations: '',
        responsable: null,
        total_value: 0,
        garantee: '',
        state: '',
        payment: '',
        payment_data: {},
        schedule_responsable: {},
        loaded_services: [],
        loaded_products: [],
        loaded_plagues: [],
        loaded_responsable: [],
        loaded_payment: [],
        step: 0,
        confirmSubmit: null,
        garantee_list: [],
        validity_list: [],
        saved: false,
        new_client: '',
        new_seller: '',
        loaded_sellers: [],
        loaded_clients: [],
        seller_name: '',
        client_name: '',
        edit: null,
        cancel_register: '',
        item_type: '',
        ...data
    })

    useEffect(() => {
        if (data.id)
            loadProposalRow(data.id, setState, setLoader)
        else
            setState({ loaded: true })
    }, [setLoader, data.id])

    useEffect(() => {
        getGarantee(setState)
    }, [])

    const validData = validateData(state)

    const stepsArray = generateSteps(state, setState, user, setLoader, item_types)
    const maxStep = stepsArray.length - 1

    return (
        <>
            {state.confirmSubmit &&
                <ConfirmationDialog
                    title={state.confirmSubmit.title}
                    handleClose={() => setState({ confirmSubmit: null })}
                    onConfirm={state.confirmSubmit.function}
                    okButton={state.confirmSubmit.okButton}
                    content={state.confirmSubmit.content}
                    message={state.confirmSubmit.message}
                />}
            {state.edit && <EditClientModal
                key={`client-modal-key${state.edit?.id}`}
                data={state.edit.data}
                handleClose={() => { setState({ edit: null }) }}
                mode='pop-up'
                types={[['CLIENT', 'Cliente']]}
                reloadData={(data) => {
                    const option = state.loaded_clients.find(each => each.id === data.id)
                    const newobj = { label: data.name, id: data.id, data: data }
                    if (!option)
                        state.loaded_clients.push(newobj)
                    else
                        Object.assign(option, newobj)
                    setState({ loaded_clients: state.loaded_clients, new_client: newobj })
                }}
            />}
            {state.loaded && <DefaultModal
                title={
                    `${getTitle(state)} proposta`
                }
                handleClose={handleClose}
                content={
                    <>
                        <Stepper activeStep={state.step}>
                            {stepsArray.map((step, index) => (
                                <Step key={step.label} >
                                    <StepLabel sx={{
                                        '.MuiStepIcon-root.Mui-active': { color: apptheme.primaryColor },
                                        '.MuiStepIcon-root.Mui-completed': { color: apptheme.primaryColor }
                                    }}>
                                        {step.label}
                                    </StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        <Box className='item-content-modal noborder' sx={{ paddingTop: '1rem' }}>
                            {stepsArray[state.step].component}
                        </Box>
                    </>
                }
                action={
                    <>

                        <Box className='action-left-options'>
                            {(user.permissions.add_client && state.step === 0 && state.state === '' && !state.client?.label) &&
                                <ColorButton
                                    onClick={() => { setState({ edit: {} }) }}
                                >
                                    Novo cliente
                                </ColorButton>}
                            {(user.permissions.change_client && state.step === 0 &&
                                (!state.client?.name && !state.client_name) && state.new_client) && <ColorButton
                                    onClick={() => { setState({ edit: state.new_client }) }}
                                >
                                    Editar cliente
                                </ColorButton>}
                            {(state.step === maxStep && state.state === 'OPEN' && user.permissions.add_proposal) &&
                                <ColorButton
                                    onClick={() => setState({
                                        confirmSubmit: {
                                            function: (message) => { cancelProposal(state, setState, setLoader, reloadData, message); setState({ confirmSubmit: null }) },
                                            title: 'Confirmar ação.',
                                            content: 'Deseja cancelar a proposta?',
                                            okButton: 'Sim',
                                            message: true
                                        }
                                    })}
                                    disabled={validData}>
                                    Cancelar
                                </ColorButton>}
                        </Box>
                        <Box className='action-right-options'>
                            {(state.step !== 0 && ['', 'OPEN'].includes(state.state)) && <ColorButton
                                onClick={() => setState({ step: state.step - 1, saved: false })}
                            >
                                Anterior
                            </ColorButton>}

                            {!state.saved && <Tooltip placement="left" title={validData}>
                                <Box>
                                    {state.step !== maxStep && <ColorButton
                                        onClick={() => setState({ step: state.step + 1 })}
                                        disabled={validData}>
                                        Próximo
                                    </ColorButton>}
                                </Box>
                                <Box>
                                    {(state.step === maxStep && ['', 'OPEN'].includes(state.state) && user.permissions.add_proposal && !state.saved) && <ColorButton
                                        onClick={() => setState({
                                            confirmSubmit: {
                                                function: () => { submit(state, reloadData, setLoader, setState); setState({ confirmSubmit: null, saved: true }) },
                                                title: 'Confirmar ação.',
                                                content: 'Deseja salvar a proposta?',
                                                okButton: 'Sim'
                                            }
                                        })}
                                        disabled={validData}>
                                        Salvar
                                    </ColorButton>}
                                </Box>
                            </Tooltip>}


                            {(state.step === maxStep && state.state === 'OPEN' && user.permissions.add_serviceorder && state.saved) &&
                                <Tooltip title={!state.saved ? 'Necessário salvar para prosseguir' : ''}><span>
                                    <ColorButton
                                        onClick={() => setState({
                                            confirmSubmit: {
                                                function: () => { initOS(state, openOS); setState({ confirmSubmit: null }) },
                                                title: 'Confirmar ação.',
                                                content: 'Deseja iniciar a ordem de serviço?',
                                                okButton: 'Sim'
                                            }
                                        })}
                                        sx={{ width: '15rem' }}
                                        disabled={validData || !state.saved}>
                                        Iniciar Ordem de serviço
                                    </ColorButton>
                                </span></Tooltip>
                            }
                        </Box>
                    </>
                }
            />}
        </>
    )
}

export default EditProposalModal