import { Box } from "@mui/material"

const validateData = (data) => {

    const response = []

    switch (data?.step){
        case 0:
            if (!data?.datetime)
                response.push(<Box>Selecione uma data.</Box>)
            if (data?.teams.length === 0)
                response.push(<Box>Selecione uma equipe.</Box>)
            break
        default:
            break
    }


    if (response.length === 0)
        return ''

    return (response)
}

export default validateData

export const validatePaymentData = (data, total_value, paid_value) => {
    const response = []

    if (data.payments.length === 0)
        response.push(<Box>Escolha uma forma de pagamento</Box>)
    if (data.payments.length > 0 && data.payments.find(each => !each.value))
        response.push(<Box>Preencha todos os valores das formas de pagamento.</Box>)
    if (total_value !== paid_value)
        response.push(<Box>O valor pago obrigatóriamente precisa ser igual o valor total.</Box>)

    if (response.length === 0)
        return ''
    return response
}